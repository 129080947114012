<template>
  <layout class="h-screen">
    <div class="bg-background h-screen">
      <div class="flex justify-center content-center items-center">
        <p class="text-center text-black text-2xl font-bold mt-6 mb-10">Interview Complete</p>
      </div>
      <div class="h-3/4 mx-8">
        <div
          class="flex flex-col justify-center content-center items-center bg-darknavy-primary mx-auto w-full h-full"
        >
          <p class="text-white text-4xl text-center font-bold">Well Done</p>
          <p
            class="text-white text-xl text-center mx-8 mt-20"
          >Your interview is complete and your answers have been saved, someone will be in contact soon. Good Luck!!</p>
          <p class="text-white text-xl text-center mx-8">You may now close this window.</p>
          <i class="fas fa-5x fa-check text-white mx-auto text-center mt-20"></i>
        </div>
        <div class="bg-buttonLower w-full h-5 -mt-px"></div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout";

export default {
  name: "CandidateComplete",
  components: {
    Layout
  },
  props: {
    success: ""
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
  watch: {}
};
</script>

<style scoped>
</style>
