<template>
  <div>
    <label v-if="label" class="form-label">{{ label }}:</label>
    <div class="w-full" :class="{ error: errors.length }">
      <input ref="file" type="file" :accept="accept" class="hidden" @change="change" />
      <div v-if="!value" class>
        <button
          type="button"
          class="py-8 px-10 bg-green w-full text-white flex flex-col justify-center content-center items-center"
          @click="browse"
        >
          <div v-if="this.title">
            <p class="text-white text-md mb-4">{{ this.title }}</p>
          </div>
          <i class="fa fa-5x fa-plus-circle text-white"></i>
        </button>
      </div>
      <div v-else class="flex items-center justify-between p-2">
        <div class="flex-1 pr-1 text-black">
          {{ value.name }}
          <span class="text-black text-xs">({{ filesize(value.size) }})</span>
        </div>
        <button
          type="button"
          class="px-4 py-1 bg-grey-dark hover:bg-grey-darker rounded-sm text-xs font-medium text-black ml-16"
          @click="remove"
        >Remove</button>
      </div>
    </div>
    <div v-if="errors.length" class="form-error">{{ errors[0] }}</div>
  </div>
</template>

<script>
export default {
  props: {
    value: File,
    label: String,
    accept: String,
    errors: {
      type: Array,
      default: () => []
    },
    title: String
  },
  watch: {
    value(value) {
      if (!value) {
        this.$refs.file.value = "";
      }
    }
  },
  methods: {
    filesize(size) {
      var i = Math.floor(Math.log(size) / Math.log(1024));
      return (
        (size / Math.pow(1024, i)).toFixed(2) * 1 +
        " " +
        ["B", "kB", "MB", "GB", "TB"][i]
      );
    },
    browse() {
      this.$refs.file.click();
    },
    change(e) {
      this.$emit("input", e.target.files[0]);
    },
    remove() {
      this.$emit("input", null);
    }
  }
};
</script>
