<template>
  <layout title="Create Interview">
    <div class="flex justify-center h-auto">
      <div
        class="w-full xl:w-4/6 mx-auto justify-center px-8 py-6 lg:px-32 lg:py-16 text-black mt-2"
      >
        <div class="flex flex-col justify-center content-center items-center">
          <p class="ii--header-text text-white mb-8">Create your interview</p>
          <p class="text-white text-md mb-16">Setup your interview in 3 easy steps</p>
        </div>
        <div class="bg-white flex flex-wrap justify-between py-10">
          <div
            class="flex flex-col justify-center content-center items-center my-3 px-3 w-1/3 overflow-hidden sm:my-3 sm:px-3 sm:w-1/3 md:my-2 md:px-2 md:w-1/3 lg:my-2 lg:px-2 lg:w-1/3 xl:my-2 xl:px-2 xl:w-1/3"
          >
            <!-- Column Content -->
            <div
              class="flex justify-center content-center items-center h-24 w-24 mx-8 rounded-full"
              :class="step >= 1 ? 'bg-cherryred-primary text-white':'bg-background text-black'"
            >
              <img src="/img/icons/pencil.png" />
            </div>
            <p class="my-2 mx-auto font-semibold">Details</p>
          </div>

          <div
            class="flex flex-col justify-center content-center items-center my-3 px-3 w-1/3 overflow-hidden sm:my-3 sm:px-3 sm:w-1/3 md:my-2 md:px-2 md:w-1/3 lg:my-2 lg:px-2 lg:w-1/3 xl:my-2 xl:px-2 xl:w-1/3"
          >
            <!-- Column Content -->
            <div
              class="flex justify-center content-center items-center h-24 w-24 mx-8 rounded-full"
              :class="step >= 2 ? 'bg-cherryred-primary text-white':'bg-background text-black'"
            >
              <img src="/img/icons/question_mark.png" />
            </div>
            <p class="my-2 mx-auto font-semibold">Questions</p>
          </div>

          <div
            class="flex flex-col justify-center content-center items-center my-3 px-3 w-1/3 overflow-hidden sm:my-3 sm:px-3 sm:w-1/3 md:my-2 md:px-2 md:w-1/3 lg:my-2 lg:px-2 lg:w-1/3 xl:my-2 xl:px-2 xl:w-1/3"
          >
            <!-- Column Content -->
            <div
              class="flex justify-center content-center items-center h-24 w-24 mx-8 rounded-full"
              :class="step >= 3 ? 'bg-cherryred-primary text-white':'bg-background text-black'"
            >
              <img src="/img/icons/invite.png" class="text-white" />
            </div>
            <p class="my-2 mx-auto font-semibold">Invite</p>
          </div>
        </div>
        <div v-show="step === 1" class="px-8 py-6 xl:py-20 xl:px-16 mx-auto bg-white -mt-1">
          <!-- <div class="flex justify-center"> -->
          <form>
            <input type="hidden" name="user_id" v-model="form.user_id" />
            <input type="hidden" name="company_id" v-model="form.company_id" />
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full px-3">
                <label for="title" class="ii--form-label">Title Of Role</label>
                <p class="text-black text-xs italic">Maximum 30 Characters</p>
                <input
                  class="ii--form-input-full focus: outline-none"
                  id="grid-first-name"
                  type="text"
                  placeholder="Job Title"
                  v-model="form.title"
                />
              </div>
              <div class="w-full px-3 mt-6">
                <label for="department" class="ii--form-label">Department</label>
                <p class="text-black text-xs italic">Maximum 30 Characters</p>
                <input
                  type="text"
                  id="department"
                  name="department"
                  class="ii--form-input-full focus: outline-none"
                  placeholder="Department"
                  v-model="form.department"
                />
              </div>
            </div>
            <!-- second boxes  -->
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full px-3">
                <label for="location" class="ii--form-label">Location of Role</label>
                <p class="text-black text-xs italic">Maximum 30 Characters</p>
                <input
                  type="text"
                  id="location"
                  name="location"
                  class="ii--form-input-full focus: outline-none"
                  placeholder="Location"
                  v-model="form.location"
                />
              </div>
              <div class="w-full px-3 mt-6">
                <label for="hiring" class="ii--form-label">Hiring Manager</label>
                <p class="text-black text-xs italic">Maximum 30 Characters</p>
                <input
                  type="text"
                  id="hiring"
                  name="hiring"
                  class="ii--form-input-full focus: outline-none"
                  placeholder="Hiring Manager"
                  v-model="form.hiring"
                />
              </div>
            </div>
            <!-- full width -->
            <div class="flex flex-wrap -mx-3 mb-16">
              <div class="w-full px-3">
                <label for="description" class="ii--form-label">Job Description</label>
                <p class="text-black text-xs italic">Keep it short and sweet</p>
                <vue-editor
                  id="description"
                  name="description"
                  class="w-full leading-tight focus:outline-none focus:bg-white h-2/4 mt-4"
                  :editorToolbar="editorToolbar"
                  v-model="form.description"
                  placeholder="Job Description"
                ></vue-editor>
              </div>
            </div>
            <div class="flex flex-wrap mb-6">
              <div class="flex flex-col w-full">
                <p class="ii--form-label">Supporting Evidence</p>
                <p
                  class="text-black text-xs italic"
                >Please upload any supporting documents for the candidate to review prior to their interview</p>
              </div>
              <div class="w-full md:w-1/2 mb-6 md:mb-0 mt-4">
                <input
                  ref="files"
                  id="files"
                  type="file"
                  multiple
                  accept="image/*, .pdf, .docx, .txt, .docx, .xlx, .xlxs, .csv"
                  @change="change"
                  class="hidden"
                />
                <button
                  type="button"
                  class="py-8 px-10 bg-green w-full text-white flex flex-col justify-center content-center items-center"
                  @click="browse"
                >
                  <i class="fa fa-5x fa-plus-circle text-white"></i>
                </button>
              </div>
              <div class="w-full md:w-1/2 pl-3 mb-6 md:mb-0 mt-4 h-40 overflow-auto">
                <table class="table table-auto">
                  <thead>
                    <th style="width: 60%">File</th>
                    <th style="width: 40%">Action</th>
                  </thead>
                  <tbody>
                    <tr class="table-row" v-for="(file, index) in form.files" :key="index">
                      <td>{{file.name.substring(0, 20)+"..." }}</td>
                      <td>
                        <a
                          class="flex items-center contents-center bg-cherryred-primary mx-auto"
                          @click="removeFile(file)"
                        >
                          <span class="text-white font-semibold mx-auto">
                            <i class="fa fa-times text-white mr-2 mt-1"></i>Delete
                          </span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="flex justify-end mb-4">
              <input
                type="button"
                @click="nextStep()"
                class="bg-cherryred-primary font-bold text-white uppercase py-4 mt-8 rounded-lg w-1/5"
                value="continue"
              />
            </div>
          </form>
        </div>
        <div
          v-show="step === 2"
          class="px-8 py-6 xl:py-20 xl:px-16 mx-auto bg-white -mt-1 text-center"
        >
          <div class="flex flex-col mb-2">
            <p class="text-lg lg:text-xl xl:text-2xl font-bold mb-4">Add Questions</p>
            <p
              class="text-sm mb-4"
            >We recommend a maximum of 12 questions, which can either be as a video response or as a text based answer, which you can toggle below. Candidates will have 3 minutes to answer each question and have up to 3 attempts to record their responses. The order of questions can be rearranged by clicking and dragging on the left hand side.</p>
            <div class="flex flex-row justify-center w-full mt-2 text-xs mb-4">
              <span class="mr-2">
                <i class="fa fa-font"></i> Text Question
              </span>
              <span>
                <i class="fa fa-video"></i> Video Question
              </span>
            </div>
          </div>
          <form>
            <draggable
              v-model="form.questions"
              @start="drag=true"
              @end="drag=true"
              handle=".handle"
            >
              <div v-for="(question, index) in form.questions" :key="index">
                <div class="flex flex-wrap -mx-3 mb-6">
                  <div
                    class="w-1/12 px-3 mb-6 md:mb-0 flex content-center items-center justify-center"
                  >
                    <i class="fa md:fa-2x fa-bars handle"></i>
                  </div>
                  <div
                    class="w-1/12 px-3 mb-6 md:mb-0 flex content-center items-center justify-center"
                  >
                    <div
                      v-for="(option, i) in selectOptions"
                      :key="i"
                      class="mx-1 py-1 px-2"
                      :class="question.type.icon == option.icon ? 'bg-green': ''"
                    >
                      <input type="radio" :id="i" class="hidden" />
                      <label
                        :for="index"
                        @click="changedType(question.type.icon, index, option.icon, i)"
                        :title="option.icon == 'fa-video' ? 'Video Question' : 'Text Question'"
                      >
                        <i class="fa md:fa-2x" :class="option.icon"></i>
                      </label>
                    </div>
                  </div>
                  <div class="w-9/12 px-3 mb-6 md:mb-0">
                    <input
                      class="ii--form-input-full focus: outline-none focus:bg-white pl-2 2xl:pl-0"
                      id="title"
                      type="text"
                      placeholder="Please enter your question here"
                      name="question[][index]"
                      v-model="question.title"
                    />
                  </div>
                  <div
                    class="w-1/12 px-3 mb-6 md:mb-0 text-red-400 flex justify-center items-center content-center"
                  >
                    <i
                      class="fa fa-trash"
                      title="Remove Question"
                      @click="removeQuestionInputs(index)"
                    ></i>
                  </div>
                </div>
              </div>
            </draggable>
            <div class="flex justify-center w-full mb-10">
              <button
                type="button"
                class="flex items-center justify-center content-center bg-green py-4 2xl:w-2/5 xl:w-3/5 w-full rounded-lg"
                @click="addQuestionInputs()"
                :disabled="this.disabled"
              >
                <i class="fa fa-2x fa-plus-circle rounded-full text-white mr-2"></i>
                <span class="text-white font-bold md:text-base xl:text-xl">
                  <span v-if="this.form.questions.length == 0">Add Question</span>
                  <span v-else>Add Another Question</span>
                </span>
              </button>
            </div>
          </form>
          <div class="flex flex-col mt-16">
            <p
              class="text-lg lg:text-xl xl:text-2xl font-bold mb-4"
            >How are you going to evaluate this candidate?</p>
            <p
              class="text-xs md:text-sm"
            >Please describe the criteria you will use to score and evaluate your candidate response</p>
          </div>
          <form class="mb-6">
            <div class="flex flex-wrap">
              <div v-for="(skill, index) in form.skills" :key="index" class="w-full mb-6 md:mb-0">
                <div class="flex">
                  <input
                    class="ii--form-input-full focus: outline-none"
                    id="title"
                    type="text"
                    v-model="skill.title"
                    placeholder="Please enter your skill here"
                  />
                  <div
                    class="w-1/12 md:w-1/12 px-3 mb-6 md:mb-0 text-red-400 flex justify-center items-center content-center"
                  >
                    <i class="fa fa-trash" @click="removeSkillInput(index)" title="Remove Skill"></i>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div class="flex w-full justify-center mb-4">
            <button
              type="button"
              class="flex items-center justify-center content-center bg-green py-4 2xl:w-2/5 xl:w-3/5 w-full rounded-lg"
              @click="addSkillInputs()"
            >
              <i class="fa fa-2x fa-plus-circle text-white mr-2"></i>
              <span class="text-white font-bold md:text-base xl:text-xl">
                <span v-if="this.form.skills.length == 0">Add Skill</span>
                <span v-else>Add Another Skill</span>
              </span>
            </button>
          </div>
          <div class="flex w-full justify-between mt-10 mb-4">
            <input
              type="button"
              class="ii--button-red w-1/5 focus:outline-none rounded-lg"
              value="previous"
              @click="previousStep()"
            />
            <input
              type="button"
              class="ii--button-red w-1/5 focus:outline-none rounded-lg"
              value="Continue"
              @click="nextStep()"
            />
          </div>
        </div>
        <div
          v-show="step === 3"
          class="px-8 py-6 xl:py-20 xl:px-16 mx-auto bg-white -mt-1 text-center"
        >
          <div class="flex flex-col justify-center items-center content-center mb-8 -mt-8">
            <p class="text-xl font-bold mb-2">Invite Team Members</p>
            <p
              class="text-sm mt-4 mb-4"
            >Invite team members to help review, rate and score your interviews. Teams can comment and discuss candidate responses and collaborate remotely to evaluate the best person for the job.</p>
          </div>
          <form id="add-team-members" name="add-team-members" data-form-team>
            <div class="flex items-center" v-for="(team, index) in form.team" :key="index">
              <div class="flex mb-6 w-11/12">
                <div class="w-full md:w-3/12 px-3 mb-6 md:mb-0 text-sm xl:text-md">
                  <input
                    class="ii--form-input-full px-4 focus: outline-none"
                    id="forename"
                    type="text"
                    placeholder="Forename"
                    v-model="team.forename"
                  />
                </div>
                <div class="w-full md:w-3/12 px-3 mb-6 md:mb-0 text-sm xl:text-md">
                  <input
                    class="ii--form-input-full px-4 focus: outline-none"
                    id="surname"
                    type="text"
                    placeholder="Surname"
                    v-model="team.surname"
                  />
                </div>
                <div class="w-full md:w-6/12 px-3 text-sm xl:text-md">
                  <input
                    type="email"
                    id="email"
                    class="ii--form-input-full px-4 focus: outline-none"
                    placeholder="Email"
                    v-model="team.email"
                  />
                </div>
              </div>
              <div class="w-1/12 flex justify-center">
                <button type="button" class="text-red-400" @click="removeFormInputs(index)">
                  <i class="fa fa-trash"></i>
                </button>
              </div>
            </div>
          </form>
          <div class="flex justify-center w-full mb-8">
            <button
              type="button"
              class="flex items-center justify-center content-center bg-green py-4 2xl:w-2/5 xl:w-3/5 w-full rounded-lg"
              @click="addFormInputs()"
            >
              <i class="fa fa-2x fa-plus-circle text-white mr-2"></i>
              <span class="text-white font-bold text-lg">Invite a Team Member</span>
            </button>
          </div>
          <span
            class="flex justify-center text-xs mx-auto mt-2"
          >Please note that a team member will be automatically invited once you publish the interview.</span>
          <div class="flex justify-between mt-10 mb-4">
            <button
              type="button"
              class="ii--button-red w-1/5 focus:outline-none rounded-lg"
              @click="previousStep()"
            >Previous</button>
            <button
              type="button"
              class="ii--button-red w-1/5 lg:w-2/6 focus:outline-none rounded-lg"
              @click="createInterview()"
            >Invite & Publish</button>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout";
import { VueEditor } from "vue2-quill-editor";
import { ToggleButton } from "vue-js-toggle-button";
import MultipleUpload from "@/Shared/MultipleUpload";
import draggable from "vuedraggable";
import vSelect from "vue-select";
import Question from "./questionForm";

export default {
  name: "Stepped",
  remember: ["form"],
  components: {
    Layout,
    VueEditor,
    ToggleButton,
    MultipleUpload,
    draggable,
    vSelect,
    Question
  },
  props: {
    interview: Object
  },
  mounted() {
    if (!_.isEmpty(this.interview)) {
      this.populateFormFromInterview();
    }
  },
  data() {
    return {
      step: 1,
      form: {
        title: "",
        department: "",
        description: "",
        location: "",
        hiring: "",
        user_id: this.$page.auth.user.id,
        company_id: this.$page.auth.user.company.id,
        questions: [],
        skills: [],
        team: [],
        files: []
      },
      editorToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }]
      ],
      selectOptions: [
        {
          type: 0,
          icon: "fa-font"
        },
        {
          type: 1,
          icon: "fa-video"
        }
      ],
      questionType: {
        type: 1,
        icon: "fa-video"
      },
      disabled: false,
      textQuestion: false,
      videoQuestion: false
    };
  },
  methods: {
    changedType(original, qIndex, replace, oIndex) {
      let type = { icon: replace, type: oIndex };
      let title = this.form.questions[qIndex].title;
      let newQ = { title, type };
      this.form.questions.splice(qIndex, 1, newQ);
    },
    populateFormFromInterview() {
      this.form.title = this.interview.title;
      this.form.department = this.interview.department;
      this.form.description = this.interview.description;
      this.form.hiring = this.interview.hiring;
      this.form.location = this.interview.location;

      _.forEach(this.interview.questions.question, (value, key) => {
        let type,
          icon = "";
        if (value.type != "file") {
          icon = "fa-font";
          type = 0;
        } else {
          icon = "fa-video";
          type = 1;
        }
        this.form.questions.push({
          title: value.question,
          type: {
            icon: icon,
            type: type
          }
        });
      });
      _.forEach(this.interview.skills.skills, (value, key) => {
        this.form.skills.push({
          title: value.skill
        });
      });
      _.forEach(this.interview.files.files, (value, key) => {
        let fetchFile = fetch("/storage/" + value.file_location)
          .then(response => response.blob())
          .then(blob => new File([blob], value.file_name, { type: blob.type }))
          .then(resp => {
            this.form.files.push(resp);
          });
      });
    },
    createInterview() {
      let data = new FormData();
      data.append("title", this.form.title);
      data.append("department", this.form.department);
      data.append("description", this.form.description);
      data.append("location", this.form.location);
      data.append("hiring", this.form.hiring);
      data.append("user_id", this.form.user_id);
      data.append("company_id", this.form.company_id);
      // questions
      for (var i = 0; i < this.form.questions.length; i++) {
        var question = this.form.questions[i];
        data.append("questions[][" + i + "]", JSON.stringify(question));
      }
      // skills
      for (var i = 0; i < this.form.skills.length; i++) {
        let skill = this.form.skills[i];
        data.append("skills[][" + i + "]", JSON.stringify(skill));
      }
      // team members
      for (var i = 0; i < this.form.team.length; i++) {
        let member = this.form.team[i];
        data.append("team[][" + i + "]", JSON.stringify(member));
      }

      // change files to array for upload
      for (var i = 0; i < this.form.files.length; i++) {
        let file = this.form.files[i];
        data.append("files[" + i + "]", file);
      }
      if (!_.isEmpty(this.interview)) {
        this.$inertia.post(
          this.$route("create.interview", this.interview.id),
          data,
          { replace: true, preserveScroll: true, preserveState: false }
        );
      } else {
        this.$inertia.post(this.$route("create.interview"), data);
      }
    },
    addQuestionInputs() {
      if (this.form.questions.length <= 11) {
        this.form.questions.push({
          title: "",
          type: {
            icon: "fa-video",
            type: 1
          }
        });
      } else {
        this.disabled = true;
        alertify.notify(
          "Sorry, you are only able to add 12 questions.",
          "warning"
        );
      }
    },
    removeQuestionInputs(index) {
      this.form.questions.splice(index, 1);
    },
    addSkillInputs() {
      this.form.skills.push({
        title: ""
      });
    },
    removeSkillInput(index) {
      this.form.skills.splice(index, 1);
    },
    addFormInputs() {
      this.form.team.push({
        forename: "",
        surname: "",
        email: ""
      });
    },
    removeFormInputs(index) {
      this.form.team.splice(index, 1);
    },
    browse() {
      this.$refs.files.click();
    },
    change(e) {
      let uploadedFiles = this.$refs.files.files;
      for (var i = 0; i < uploadedFiles.length; i++) {
        this.form.files.push(uploadedFiles[i]);
      }
    },
    removeFile(file) {
      this.form.files.splice(this.form.files.indexOf(file), 1);
    },
    mapObjectToArray(obj) {
      let result = Object.keys(obj).map(function(key) {
        return [Number(key), obj[key]];
      });
    },
    nextStep() {
      this.step++;
    },
    previousStep() {
      this.step--;
    }
  }
};
</script>
