<template>
  <layout title="Dashboard">
    <div class="bg-background">
      <div class="flex justify-center">
        <div class="flex mt-6 mb-6 md:mt-12 md:mb-12">
          <p class="text-xl md:text-4xl font-thin text-black text-center">Hello, {{ name }}</p>
        </div>
      </div>
      <div class="flex flex-wrap mx-1 overflow-hidden sm:-mx-1 md:-mx-1 lg:mx-1 xl:mx-1">
        <div class="w-full overflow-hidden px-1 md:px-4 mb-4">
          <div class="flex flex-col">
            <div class="bg-darknavy-primary w-full flex justify-center md:justify-between">
              <div class="w-full flex content-center items-center ml-3 md:ml-10 my-4">
                <p class="text-3xl">Your Interviews</p>
              </div>
              <div
                class="w-1/5 lg:w-2/5 flex justify-end content-center items-center mr-2 lg:mx-auto"
              >
                <inertia-link
                  :href="$route('interview.create')"
                  class="bg-cherryred-primary text-white font-bold uppercase text-center flex justify-center content-center items-center rounded-full md:rounded-l-full md:rounded-r-none w-12 h-12 lg:h-full lg:w-full"
                >
                  <i class="fa fa-plus text-white lg:mr-3"></i>
                  <span class="hidden lg:block text-xl">Create new interview</span>
                </inertia-link>
              </div>
            </div>
            <div class="flex flex-col lg:flex-wrap bg-white">
              <table class="hidden lg:table table-striped my-4 mx-4 md:my-8 md:mx-8">
                <thead class="bg-dashboard-top text-black">
                  <th class="w-1/4 px-3 py-4">Title</th>
                  <th class="w-1/4 px-1">Location</th>
                  <th class="w-1/4 px-1">Created</th>
                  <th class="w-1/4 px-1">Interview</th>
                </thead>
                <tbody class="text-black">
                  <tr
                    v-for="(interview, index) in paginatedInterviews"
                    :key="index"
                    style="height: 75px; line-height:30px;"
                    class="mt-2"
                  >
                    <td class="px-3" :title="interview.title">{{ interview.title | truncateText }}</td>
                    <td class="px-1">{{ interview.location }}</td>
                    <td class="px-1">{{ interview.created_at | formatDate }}</td>
                    <td class="flex px-1 content-center items-center mt-3">
                      <inertia-link
                        :href="$route('show.candidates', interview.id)"
                        class="bg-darknavy-primary text-white py-2 px-6 font-bold mr-2 rounded-lg"
                      >View</inertia-link>
                      <inertia-link
                        :href="$route('edit.interview', interview.id)"
                        class="bg-cherryred-primary text-white py-2 px-6 font-bold rounded-lg"
                      >Edit</inertia-link>
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- Mobile view of jobs -->
              <div
                class="flex flex-col lg:hidden mx-4 md:my-8 md:mx-8"
                v-for="(interview, index) in paginatedInterviews"
                :key="index"
              >
                <div class="flex flex-col justify-start text-black mb-2">
                  <p>
                    <span class="font-bold mr-1">Title:</span>
                    {{ interview.title | truncateText }}
                  </p>
                  <p>
                    <span class="font-bold mr-1">Location:</span>
                    {{ interview.location }}
                  </p>
                  <p>
                    <span class="font-bold mr-1">Created:</span>
                    {{ interview.created_at | formatDate }}
                  </p>
                </div>
                <div class="flex flex-row w-full mx-auto justify-center">
                  <inertia-link
                    :href="$route('show.candidates', interview.id)"
                    class="bg-darknavy-primary text-white text-center py-2 px-6 font-bold mx-1 w-1/3 rounded-lg"
                  >View</inertia-link>
                  <inertia-link
                    :href="$route('edit.interview', interview.id)"
                    class="bg-cherryred-primary text-white text-center py-2 px-6 font-bold mx-1 w-1/3 rounded-lg"
                  >Edit</inertia-link>
                </div>
                <hr class="mt-2 border border-black mb-2" />
              </div>

              <div class="flex mx-auto mb-2 text-black">
                <button
                  type="button"
                  class="mx-2"
                  @click="previousPage(1)"
                  :disabled="pageNumber==0"
                >
                  <i class="fa fa-chevron-left"></i>
                </button>
                <span v-if="this.pageCount != 0">{{ this.pageNumber +1 }} / {{ this.pageCount }}</span>
                <span v-else>{{ this.pageNumber }} / {{ this.pageCount }}</span>
                <button
                  type="button"
                  class="mx-2"
                  @click="nextPage(1)"
                  :disabled="pageNumber >= pageCount -1"
                >
                  <i class="fa fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- bottom Table -->
        <div class="w-full overflow-hidden px-1 md:px-4 mb-4">
          <div class="flex flex-col w-full">
            <div class="bg-darknavy-primary w-full flex justify-center md:justify-start">
              <p class="text-3xl md:ml-10 float-left my-4">Latest Interview Activity</p>
            </div>
            <div class="flex flex-col lg:flex-wrap bg-white p-8">
              <div
                class="my-1 px-1 w-full md:w-1/2 overflow-hidden sm:my-1 sm:px-1 md:my-1 md:px-1 xl:w-1/4"
                v-for="(interview, index) in paginatedActivity"
                :key="index"
              >
                <div class="bg-navy-primary h-64 mx-auto">
                  <div class="flex flex-col justify-center items-center content-center">
                    <inertia-link
                      class="mt-4 text-lg font-bold"
                      :href="$route('show.candidates', interview.id)"
                      :title="interview.title"
                    >{{ interview.title | shortTitle }}</inertia-link>
                    <div
                      class="flex flex-col justify-center items-center h-32 w-32 rounded-full bg-dashboard mt-8"
                    >
                      <p
                        class="text-5xl text-black font-bold -mt-2"
                      >{{ completedInterview(interview.id)}}</p>
                      <p class="-mt-3 text-sm text-gray-800">Applcants</p>
                    </div>
                  </div>
                  <div class="bg-buttonLower relative mt-10 w-full h-5"></div>
                </div>
              </div>

              <!-- pagination -->
              <div class="flex mx-auto mb-2 text-black mt-4">
                <button
                  type="button"
                  class="mx-2"
                  @click="prevActivityPage(1)"
                  :disabled="activtyPage==0"
                >
                  <i class="fa fa-chevron-left"></i>
                </button>
                <span
                  v-if="this.activityCount != 0"
                >{{ this.activtyPage +1 }} / {{ this.activityCount }}</span>
                <span v-else>{{ this.activtyPage }} / {{ this.activityCount }}</span>
                <button
                  type="button"
                  class="mx-2"
                  @click="nextActivityPage(1)"
                  :disabled="activtyPage >= activityCount -1"
                >
                  <i class="fa fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import _ from "lodash";
import Layout from "@/Shared/Layout";

export default {
  components: {
    Layout
  },
  props: {
    errors: Object,
    user: Object,
    flash: Object,
    candidates: Array,
    interviews: Object | Array
  },
  data() {
    return {
      name: this.$page.auth.user.name,
      pageNumber: 0,
      rowsPerPage: 5,
      activtyPage: 0,
      activityPerPage: 4
    };
  },
  methods: {
    completedInterview(id) {
      let candidate = _.filter(this.candidates, function(o) {
        if (o.interview_id == id && o.complete == 1) {
          return true;
        }
        return false;
      });
      return candidate.length;
    },
    nextPage() {
      this.pageNumber++;
    },
    previousPage() {
      this.pageNumber--;
    },
    nextActivityPage() {
      this.activtyPage++;
    },
    prevActivityPage() {
      this.activtyPage--;
    }
  },
  computed: {
    pageCount() {
      let l = this.interviews.length,
        s = this.rowsPerPage;
      return Math.ceil(l / s);
    },
    paginatedInterviews() {
      const start = this.pageNumber * this.rowsPerPage,
        end = start + this.rowsPerPage;
      return this.interviews.slice(start, end);
    },
    activityCount() {
      let l = this.interviews.length,
        s = this.activityPerPage;
      return Math.ceil(l / s);
    },
    paginatedActivity() {
      const start = this.activtyPage * this.activityPerPage,
        end = start + this.activityPerPage;
      return this.interviews.slice(start, end);
    }
  },
  mounted() {
    setInterval(() => {
      if (this.activtyPage + 1 < this.activityCount) {
        this.activtyPage++;
      } else if (this.activtyPage + 1 >= this.activityCount) {
        this.activtyPage = 0;
      }
    }, 15000);
  }
};
</script>
