<template>
  <div class="flex flex-wrap -mx-1 overflow-hidden sm:-mx-px md:-mx-px lg:-mx-px xl:-mx-px">
    <div
      class="my-1 px-1 w-full overflow-hidden sm:my-px sm:px-px md:my-px md:px-px lg:my-px lg:px-px lg:w-full xl:my-px xl:px-px xl:w-full"
    >
      <login></login>
    </div>
    <div
      class="my-1 px-1 w-full overflow-hidden sm:my-px sm:px-px md:my-px md:px-px lg:my-px lg:px-px lg:w-full xl:my-px xl:px-px xl:w-full"
    >
      <register></register>
    </div>
  </div>
</template>

<script>
import Login from "../Pages/Auth/Login.vue";
import Register from "../Pages/Auth/Register.vue";

export default {
  name: "LoginScreen",
  components: {
    Login,
    Register
  },
  props: {},
  data() {
    return {};
  },
  methods: {},
  computed: {},
  watch: {}
};
</script>
