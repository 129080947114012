<template>
  <div class="w-full text-black">
    <div
      class="flex h-8 content-center items-center"
      v-for="(c,i) in JSON.parse(analysis)"
      :key="i"
    >
      <div class="w-2/12">
        <p>{{ c.Type }}</p>
      </div>
      <div class="w-8/12">
        <div class="w-full bg-background">
          <div class="h-3 bg-cherryred-primary rounded-r-lg" :style="{ width: c.Confidence + '%' }"></div>
        </div>
      </div>
      <div class="w-2/12 text-right">
        <p>{{ percentage(c.Confidence) }} %</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    analysis: String
  },
  data() {
    return {};
  },
  methods: {
    percentage(val) {
      return Math.round(val, 4);
    }
  },
  computed: {},
  watch: {}
};
</script>

<style scoped>
</style>
