<template>
  <layout class="h-screen">
    <div class="flex flex-col justify-center content-center items-center h-auto">
      <p class="ii--header-text mt-10 mb-8">Reset Password</p>
    </div>
    <div
      class="flex flex-wrap mx-1 overflow-hidden sm:mx-px md:mx-px lg:mx-px xl:mx-px mt-2 xl:mt-12 md:mt-8"
    >
      <div
        class="my-1 px-1 w-full md:w-full overflow-hidden sm:my-px sm:px-px md:my-px md:px-px lg:my-px lg:px-px xl:my-6 xl:px-px"
      >
        <password-reset />
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout";
import PasswordReset from "./Auth/Reset";
export default {
  name: "Reset",
  components: {
    Layout,
    PasswordReset
  }
};
</script>
