<template>
  <transition type="modal-fade">
    <div class="modal w-1/2 mt-10 mb-10 mx-auto top-0 left-0">
      <div class="modal-header">
        <button type="button" class="text-black float-right" @click="close">
          <i class="fa fa-2x fa-times"></i>
        </button>
      </div>
      <div class="modal-body flex flex-col justify-center">
        <p>text...</p>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "FileModal",
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>
