<template>
  <div class="flex flex-col bg-black justify-center content-center items-center h-full">
    <!-- <video
      id="InterviewIntelligenceVideoPlayer"
      ref="videoPlayer"
      class="video-js vjs-big-play-centered mx-auto"
      :src="this.videoFile"
    ></video>-->

    <video
      ref="myVideo"
      controls
      controlslist="nodownload"
      :src="this.videoFile"
      class="h-full"
      oncontextmenu="return false;"
    ></video>
  </div>
</template>

<script>
//parent videojs
import "video.js/dist/video-js.css";
import "videojs-record/dist/css/videojs.record.css";

import videojs from "video.js";
import "videojs-flash";

export default {
  name: "InterviewIntelligenceVideoPlayer",
  props: {
    videoFile: {
      type: String
    }
  },
  data() {
    return {
      myVideo: null,
      player: null,
      options: {
        autoplay: false,
        controls: true,
        controlBar: {
          volumeMenuButton: {
            inline: false,
            vertical: true
          }
        },
        pip: true,
        debug: true,
        width: 940,
        height: 540,
        techOrder: ["html5"],
        sources: [
          {
            src: "",
            type: "video/webm"
          }
        ]
      }
    };
  },
  mounted() {
    // this.playerInitialize();
    // this.playerSetupEvents();
  },
  methods: {
    playerInitialize() {
      this.player = videojs(this.$refs.videoPlayer, this.options);
    },
    errorMessages() {
      return this.player.error().message;
    },
    playerSetupEvents() {
      this.player.on("error", () => {
        this.errorMessages();
      });
    }
  },
  watch: {
    videoFile: {
      handler: function(val) {
        // this.$set(this.player.options_.sources, 0, {
        //   type: "video/mp4",
        //   src: val
        // });
      },
      deep: true
    }
  }
};
</script>
