<template>
  <layout title="Candidate Profiles">
    <div class="flex flex-auto">
      <!-- Left Col -->
      <div
        class="hidden md:flex bg-navy-primary w-full md:w-4/12 lg:w-2/12 mr-3 min-h-screen flex-shrink-0"
      >
        <sidebar :interview="interview" @filter="filterBy" />
      </div>
      <!-- Right Col -->
      <div
        class="flex flex-wrap md:flex-grow bg-background h-auto md:h-auto justify-center text-black"
      >
        <div class="flex flex-wrap w-full md:hidden">
          <!-- hidden on small quick links to interviews only on large> -->
        </div>
        <div class="mt-10 mx-10 w-full">
          <div
            class="flex flex-col justify-center items-center mx-auto lg:flex-row lg:justify-between"
          >
            <p
              class="text-black text-center lg:text-left"
              :class="interview.title.length > 60 ? 'text-2xl lg:text-xl' : 'text-2xl lg:ii--header-text'"
            >{{ interview.title}}</p>
            <div class="flex text-white text-sm">
              <button
                type="button"
                class="text-center w-full lg:w-1/2 font-bold h-10 lg:h-16 bg-cherryred-primary mx-1 px-4 mt-2 lg:mt-0 rounded-lg"
                @click="addCandidate()"
              >Invite Candidate</button>
              <button
                type="button"
                class="hidden lg:block text-center font-bold lg:h-16 bg-darknavy-primary mx-1 px-4 rounded-lg"
                @click="addMultipleCandidates()"
              >Invite Multiple</button>
            </div>
          </div>
          <div class="flex flex-wrap -mx-2">
            <div
              class="xl:w-1/5 md:w-2/4 w-full px-4 my-4"
              v-for="(profile, index) in candidates"
              :key="index"
            >
              <div v-if="profile.complete == 1">
                <profile-card :profile="profile" :location="interview.location"></profile-card>
              </div>
              <div v-else>
                <profile-card
                  class="ii--profile-disabled text-white"
                  :profile="profile"
                  :location="interview.location"
                  v-on:candidate:deleted="pushDelete($event)"
                ></profile-card>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="fixed inset-0 w-full h-screen flex items-center justify-center bg-modal"
        v-show="inviteSingular"
      >
        <invite-candidate
          :interview="interview"
          @close="inviteSingular = !inviteSingular"
          class="bg-white shadow-lg p-8"
        />
      </div>

      <div
        class="w-full overflow-y-hidden h-screen absolute flex items-center justify-center content-center"
        v-show="inviteMultiple"
      >
        <invite-multiple
          :interview="interview"
          class="bg-white shadow-lg p-8"
          @close="inviteMultiple = !inviteMultiple"
        />
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout";
import ProfileCard from "./ProfileCard.vue";
import Sidebar from "../../components/Sidebar.vue";
import InviteCandidate from "../../components/InviteCandidates.vue";
import inviteMultiple from "../../components/InviteMultiple.vue";
import _ from "lodash";

export default {
  name: "",
  components: {
    Layout,
    ProfileCard,
    Sidebar,
    InviteCandidate,
    inviteMultiple
  },
  props: {
    interview: Object,
    candidate: Object | Array
  },
  data() {
    return {
      inviteSingular: false,
      inviteMultiple: false,
      candidates: {}
    };
  },
  methods: {
    pushDelete(e) {
      const candidate = _.findIndex(this.candidates, c => {
        return c.id === e;
      });
      this.candidates.splice(candidate, 1);
    },
    addCandidate() {
      this.inviteSingular = !this.inviteSingular;
      this.inviteMultiple = false;
    },
    addMultipleCandidates() {
      this.inviteMultiple = !this.inviteMultiple;
      this.inviteSingular = false;
    },
    filterBy(filter) {
      if (filter == "all") {
        this.candidates = this.candidate;
      }
      if (filter == "complete") {
        this.candidates = _.filter(this.candidate, { complete: 1 });
      }

      if (filter == "invited") {
        this.candidates = _.filter(this.candidate, { complete: 0 });
      }

      if (filter == "good") {
        let good = [];
        _.forEach(this.candidate, c => {
          axios
            .get(this.$route("candidate.average.rating", c.id))
            .then(response => {
              if (response.data.rating != 0) {
                good.push(c);
              }
            });
        });
        this.candidates = good;
      }
      if (filter == "bad") {
        let good = [];
        _.forEach(this.candidate, c => {
          axios
            .get(this.$route("candidate.average.rating", c.id))
            .then(response => {
              if (response.data.rating < 2) {
                good.push(c);
              }
            });
        });
        this.candidates = good;
      }
    }
  },
  mounted() {
    this.candidates = _.orderBy(this.candidate, "complete", "desc");
  }
};
</script>
