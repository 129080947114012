<template>
  <layout></layout>
</template>

<script>
import Layout from "@/Shared/Layout";
export default {
  components: {
    Layout
  }
};
</script>
