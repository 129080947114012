<template>
  <div>
    <div class="flex flex-wrap w-full my-2" v-for="(comment, index) in comments" :key="index">
      <div class="h-10 flex">
        <p class="text-left font-semibold mr-2">{{ comment.commentorName }}</p>
        <p
          class="text-xs font-light mt-1 ml-4 subpixel-antialiased"
        >{{ comment.date | commentDate }}</p>
      </div>
      <div class="speech-bubble flex w-full h-auto mt-3 px-4 py-4 mr-4">
        <div class="w-11/12">
          <p>{{ comment.comment }}</p>
        </div>
        <div class="w-1/12 text-right" v-if="comment.name = $page.auth.user.name">
          <i class="fa fa-times-circle text-red-700" @click="deleteComment(comment.id)"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamComment",
  props: {
    comments: Array,
    candidate: Number,
    interview: Number,
    company: Number
  },
  data() {
    return {};
  },
  methods: {
    deleteComment(id) {
      axios.delete("/candidates/delete-comment/" + id).then(response => {
        if (response.data.success) {
          _.findIndex(this.comments, (c, i) => {
            if (c.id == id) {
              this.comments.splice(i, 1);
            }
          });
        }
      });
    }
  },
  computed: {},
  watch: {}
};
</script>
