<template>
  <div
    class="flex flex-col justify-center bg-white h-auto text-black"
    :class="complete ? '': 'ii--profile-disabled'"
  >
    <div class="m-8 md:mb-8 mx-auto">
      <img
        :src="profile.photo"
        class="w-48 h-48 md:w-32 md:h-32 lg:w-40 lg:h-40 xl:w-40 xl:h-40 rounded-full border-4 border-white"
      />
    </div>
    <p class="text-center capitalize -mt-6">{{ profile.name }}</p>
    <star-rating
      v-model="rating"
      :increment="0.1"
      :read-only="true"
      :show-rating="false"
      :star-size="18"
      activeColor="#b50227"
      class="-mt-1 mb-2 lg:mb-6 mx-auto"
    ></star-rating>
    <div class="flex flex-wrap lg:flex-row mb-4">
      <!-- <div class="w-full lg:w-1/2 text-center text-sm">
        <i class="fa fa-map-marker"></i>
        <p class>Location</p>
        <p class>{{ location }}</p>
      </div>-->
      <div class="w-full w-full text-center text-sm">
        <i class="fa fa-calendar"></i>
        <div v-if="profile.complete">
          <p class>Completed</p>
          <p class>{{ profile.completed_on | invitedDate }}</p>
        </div>
        <div v-else>
          <p class>Invited On</p>
          <p class>{{ profile.updated_at | invitedDate }}</p>
        </div>
      </div>
    </div>
    <div
      class="bg-darknavy-primary text-white h-10 flex justify-center items-center w-full"
      v-if="profile.complete == 1"
    >
      <inertia-link :href="$route('candidate.profile', profile.id)" class="mx-auto font-bold">View</inertia-link>
    </div>
    <div v-else class="bg-dashboard-top text-white h-10 flex w-full">
      <button
        @click="deleteCandidate(profile.id)"
        class="w-1/2 text-center font-bold h-full bg-cherryred-primary"
      >Delete</button>
      <button
        @click="reinviteCandidate(profile.id)"
        class="w-1/2 text-center font-bold h-full bg-darknavy-primary"
      >Re-Invite</button>
    </div>
  </div>
</template>

<script>
import StarRating from "vue-star-rating";
export default {
  name: "ProfileCard",
  components: {
    StarRating
  },
  props: {
    profile: Object,
    location: String
  },
  data() {
    return {
      rating: 0,
      complete: this.profile.complete
    };
  },
  mounted() {
    this.getCandidateRating();
    this.fit();
  },
  methods: {
    getCandidateRating() {
      axios
        .get(this.$route("candidate.average.rating", this.profile.id))
        .then(response => {
          this.rating = response.data.rating;
        });
    },
    reinviteCandidate(val) {
      axios
        .post(this.$route("reinvite.candidate", val))
        .then(response => {
          alertify.success(response.data.message);
        })
        .catch(error => {
          alertify.errors(response.data.message);
        });
    },
    deleteCandidate(val) {
      var conf = confirm("Are you sure you wish to delete this candidate");
      if (conf) {
        axios
          .post(this.$route("delete.candidate", val))
          .then(response => {
            this.$emit("candidate:deleted", val);
            alertify.success(response.data.message);
          })
          .catch(error => {
            alertify.errors(response.data.message);
          });
      }
    },
    fit() {
      axios
        .get(this.$route("get.candidate.rating", this.profile.id))
        .then(response => {});
    }
  },
  computed: {},
  watch: {}
};
</script>
