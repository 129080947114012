<template>
  <layout>
    <div class="flex flex-col justify-center content-center items-center h-screen">
      <div class="flex -mt-28">
        <p class="ii--header-text">Verify your account</p>
      </div>
      <div
        class="flex flex-col justify-center content-center items-center bg-white md:w-1/2 mr-auto ml-auto py-16 mt-16"
      >
        <p
          class="text-md mt-4 text-black mx-4 text-center"
        >Please verify your email address before proceeding</p>

        <a
          href="/email/resend"
          class="ii--button-red px-6 mt-10 text-center"
        >Resend Verification Email</a>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout";

export default {
  name: "",
  components: {
    Layout
  },
  props: {},
  data() {
    return {};
  },
  methods: {},
  computed: {},
  watch: {}
};
</script>
