<template>
  <layout title="Admin" class="min-h-full">
    <div class="flex flex-auto">
      <div class="hidden md:flex bg-navy-primary w-full md:w-4/12 lg:w-2/12 mr-3 h-auto">
        <div class="w-full py-8 px-8">
          <div class="flex items-center my-10">
            <div class="w-1/3 mr-4">
              <p class="text-md text-white leading-relaxed">{{ this.$page.auth.user.first_name }}</p>
              <p
                class="text-lg text-white leading-relaxed font-bold"
              >{{ this.$page.auth.user.last_name }}</p>
            </div>
            <div class="w-2/3">
              <img
                class="border-4 border-white rounded-full w-24 float-right"
                :src="this.$page.auth.user.company.logo"
              />
            </div>
          </div>
          <hr class="border-gray-100 opacity-25 my-10" />
          <div class="flex flex-col justify-start">
            <div class="py-4">
              <p class="text-cherryred-primary text-sm leading-tight font-bold">Website</p>
              <p
                class="text-white text-xs leading-snug mt-2"
              >{{ this.$page.auth.user.company.website }}</p>
            </div>
            <div class="py-4">
              <p class="text-cherryred-primary text-sm leading-tight font-bold">Email</p>
              <p class="text-white text-xs leading-snug mt-2">{{ this.$page.auth.user.email }}</p>
            </div>
            <div class="py-4">
              <p class="text-cherryred-primary text-sm leading-tight font-bold">Phone Number:</p>
              <p
                class="text-white text-xs leading-snug mt-2"
              >{{ this.$page.auth.user.company.phone }}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex flex-wrap md:flex-grow bg-background h-auto md:h-auto justify-center text-black"
      >
        <p>Admin Page</p>

        <div class="px-2 bg-white w-full">
          <div class="bg-darknavy-primary w-full flex justify-center md:justify-start">
            <p class="text-white text-3xl md:ml-4 float-left my-4">List of Failed Jobs</p>
          </div>
          <table class="w-full table-auto">
            <thead>
              <tr>
                <th class="px-4 py-2">Connection</th>
                <th class="px-4 py-2">Queue</th>
                <th class="px-4 py-2">Failed</th>
                <th class="px-4 py-2">Error</th>
                <th class="px-4 py-2">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr class="px-4 py-2" v-for="(job, index) in failed" :key="index">
                <!-- <td>{{ job.id }}</td> -->
                <td class="px-4 py-2">{{ job.connection }}</td>
                <td class="px-4 py-2">{{ job.queue }}</td>
                <td class="px-4 py-2">{{ job.failed_at }}</td>
                <td class="px-4 py-2">{{ job.exception.split('/')[0] }}</td>
                <td class="px-4 py-2">
                  <p @click="retryJob(job.id)">Retry</p>
                  <p>delete</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout";

export default {
  name: "Admin",
  components: {
    Layout
  },
  props: {
    failed: Object
  },
  data() {
    return {
      interviews: {
        candidateCount: null
      },
      interviewCount: null,
      companies: {},
      companiesCount: null,
      users: {},
      userCount: {}
    };
  },
  mounted() {
    this.getAllCompanies();
    this.getAllUsers();
    this.getAllInterviews();
  },
  methods: {
    getAllCompanies() {
      axios.get(this.$route("admin.companies")).then(response => {
        if (response) {
          this.companies = response.data;
          this.companiesCount = response.data.length;
        }
      });
    },
    getAllUsers() {
      axios.get(this.$route("admin.users")).then(response => {
        if (response) {
          this.users = response.data;
          this.userCount = response.data.length;
        }
      });
    },
    getAllInterviews() {
      axios.get(this.$route("admin.interview")).then(response => {
        console.log(response);
        if (response) {
          this.interviews = response.data;
          this.interviewsCount = response.data.length;
        }
      });
    },
    retryJob(id) {
      axios.post(this.$route("admin.retry.job", id)).then(response => {
        console.log(response);
      });
    }
  },
  computed: {},
  watch: {}
};
</script>
