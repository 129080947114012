<template>
  <div class="flex justify-center items-center content-center mr-2">
    <div>
      <p
        class="flex justify-center items-center bg-black rounded-full w-8 h-8 text-white font-bold mr-2"
      >{{ this.$page.auth.user.first_name | firstLetter }} {{ this.$page.auth.user.last_name | firstLetter }}</p>

      <i class="fa fa-chevron-down" @click="showMenu = !showMenu"></i>
    </div>
    <!-- <div :class="showMenu ? 'block' : 'hidden'" class="bg-white mt-4 rounded-lg py-2 shadow-xl">
      <inertia-link
        class="block mt-1 lg:mr-4 hover:text-cherryred-primary focus:text-cherryred-primary lg:mt-0 px-4 py-2"
        href="/logout"
        method="post"
      >Logout</inertia-link>
    </div>-->
  </div>
</template>

<script>
export default {
  name: "ProfileNavigation",
  props: {},
  data() {
    return {
      showMenu: false
    };
  },
  methods: {},
  computed: {},
  watch: {}
};
</script>

<style scoped>
</style>
