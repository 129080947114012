<template>
  <transition type="modal-fade">
    <div class="modal w-full h-full lg:w-1/2 mt-10 mb-10 mx-auto top-0 left-0 overflow-y-scroll">
      <div class="modal-header">
        <button type="button" class="text-black float-right" @click="close">
          <i class="fa fa-2x fa-times"></i>
        </button>
      </div>
      <div class="modal-body flex flex-col text-justify text-black">
        <p class="font-bold text-2xl mb-4">Privacy Policy</p>
        <div class="mb-4 break-words">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tristique et egestas quis ipsum suspendisse ultrices gravida dictum. Egestas congue quisque egestas diam. Massa tempor nec feugiat nisl pretium fusce. Urna cursus eget nunc scelerisque viverra mauris. Consectetur libero id faucibus nisl tincidunt eget nullam. Nulla facilisi nullam vehicula ipsum a arcu. Dui accumsan sit amet nulla facilisi morbi tempus. In dictum non consectetur a erat nam. Tellus orci ac auctor augue. Vestibulum lorem sed risus ultricies tristique. Adipiscing bibendum est ultricies integer quis auctor. Vel facilisis volutpat est velit egestas dui. Fermentum iaculis eu non diam phasellus vestibulum lorem. Velit aliquet sagittis id consectetur purus ut faucibus pulvinar. Sed egestas egestas fringilla phasellus faucibus scelerisque eleifend donec pretium. Diam maecenas ultricies mi eget mauris pharetra et ultrices neque. Massa eget egestas purus viverra accumsan.</p>
          <p>Volutpat ac tincidunt vitae semper quis lectus nulla at volutpat. Tempus iaculis urna id volutpat lacus laoreet non curabitur. Mauris sit amet massa vitae tortor condimentum. Dictum non consectetur a erat nam at lectus. Nec tincidunt praesent semper feugiat nibh sed pulvinar proin. Interdum velit euismod in pellentesque massa placerat duis ultricies. Convallis a cras semper auctor neque vitae. Massa vitae tortor condimentum lacinia quis. Mattis enim ut tellus elementum sagittis. Enim eu turpis egestas pretium aenean. Sed adipiscing diam donec adipiscing. Vestibulum lectus mauris ultrices eros in cursus turpis massa tincidunt. Viverra tellus in hac habitasse platea dictumst vestibulum. Tincidunt lobortis feugiat vivamus at augue eget arcu dictum. Aenean vel elit scelerisque mauris pellentesque pulvinar.</p>
        </div>
      </div>
      <div class="modal-footer"></div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "PrivacyPolicy",
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>
