<template>
  <div class>
    <table class="w-full">
      <thead class="flex justify-around">
        <th class="py-4 my-2 mx-auto" v-for="(header, index) in headers" :key="index">{{header}}</th>
      </thead>
      <tbody class="flex flex-row justify-around">
        <tr v-for="(row, index) in rows" :key="index">
          <td>{{ row }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "Table",
  props: {
    cols: {
      Type: Number,
      default: 2
    },
    headers: {
      Type: Array,
      default: () => ["col", "col", "col", "col", "col", "col"]
    },
    rows: {
      Type: Array,
      default: () => [["1", "test"], ["2", "test"]]
    },
    pagination: {
      Type: Boolean,
      default: false
    },
    itemsPerPage: {
      Type: Number,
      default: 5
    }
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
  watch: {}
};
</script>
