<template>
  <!-- NEEDS RENAMED!!! -->
  <div class="flex w-full">
    <div class="w-3/12 mr-4">
      <img :src="question.imageUrl" />
    </div>
    <div class="w-9/12">
      <p>{{ question.question }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    question: Object
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
  watch: {}
};
</script>
