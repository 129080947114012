<template>
    <div class="col-12 image-uploader">
        <div class="img-preview" v-if="dataUrl">
            <img :src="dataUrl">
        </div>
        <div class="btn upload-btn" v-else>
            <span>Upload Image</span>
        </div>
        <div class="file-field input-field">
            <input type="file" @input="fileChange($event.target.name, $event.target.files);" accept="image/*" :id="inputId">
        </div>
    </div>
</template>

<script>
export default {
    props : {
        value : {
            default : null,
            type    : String|Function|Object|null
        },
        originalValue : {
            default : null,
            type    : String|null
        }
    },
    data : function () {
        return {
            usingOrig : true,
            content   : this.value,
            origImg   : null,
            dataUrl   : null,
            reader    : null,
            inputId   : this.$root.uuid()
        }
    },
    watch : {
        content : function (val) {
            if (val) {
                this.reader.readAsDataURL(val);
            } else {
                this.dataUrl = this.origImg;
            }
        },
        originalValue : function (val) {
            if (this.originalValue) {
                this.origImg = _.clone(this.originalValue);
            } else {
                this.origImg = null;
            }
        },
        origImg : function (val) {
            this.setDataUrl(val);
        }
    },
    mounted : function () {
            let self   = this;
            this.reader = new FileReader();
            this.reader.addEventListener("load", function () {
                self.dataUrl = self.reader.result;
            }, false);

            if (this.originalValue) {
                this.origImg = _.clone(this.originalValue);
            }
    },
    methods : {
        setDataUrl : function (val) {
            this.dataUrl = val;
        },
        clearImage : function () {
            this.content = null;
            this.$emit('input', null);
            this.usingOrig = true;
            $('#' + this.inputId).val(null);
        },
        fileChange : function (name, files) {
            this.content = files[0];
            this.usingOrig = false;
            this.$emit('input', this.content);
        }
    }
}
</script>
