<template>
  <layout title="Accounts">
    <div class="flex flex-auto">
      <!-- Left Col -->
      <div class="hidden md:flex bg-navy-primary w-full md:w-4/12 lg:w-2/12 mr-3 h-auto">
        <div class="w-full py-8 px-8">
          <div class="flex items-center my-10">
            <div class="w-1/3 mr-4">
              <p class="text-md text-white leading-relaxed">{{ this.$page.auth.user.first_name }}</p>
              <p
                class="text-lg text-white leading-relaxed font-bold"
              >{{ this.$page.auth.user.last_name }}</p>
            </div>
            <div class="w-2/3">
              <img
                class="border-4 border-white rounded-full w-24 float-right"
                :src="this.$page.auth.user.company.logo"
              />
            </div>
          </div>
          <hr class="border-gray-100 opacity-25 my-10" />
          <div class="flex flex-col justify-start">
            <div class="py-4">
              <p class="text-cherryred-primary text-sm leading-tight font-bold">Website</p>
              <p
                class="text-white text-xs leading-snug mt-2"
              >{{ this.$page.auth.user.company.website }}</p>
            </div>
            <div class="py-4">
              <p class="text-cherryred-primary text-sm leading-tight font-bold">Email</p>
              <p class="text-white text-xs leading-snug mt-2">{{ this.$page.auth.user.email }}</p>
            </div>
            <div class="py-4">
              <p class="text-cherryred-primary text-sm leading-tight font-bold">Phone Number:</p>
              <p
                class="text-white text-xs leading-snug mt-2"
              >{{ this.$page.auth.user.company.phone }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Right Col -->
      <div
        class="flex container flex-wrap md:flex-grow bg-background h-auto md:h-auto justify-center text-black"
      >
        <div class="w-full lg:w-3/4 bg-white mt-8 mb-8 px-2 py-4 lg:px-16 lg:pt-4 lg:pb-8">
          <p class="font-bold lg:mx-12 mt-12 text-md">User Details</p>
          <div class="flex flex-wrap lg:mx-8 mb-6">
            <div class="w-full px-3 mb-6 md:mb-0 md:w-1/2">
              <input
                class="appearance-none bg-transparent border-b py-6 w-full text-gray-900 mr-3 px-1 leading-relaxed focus:outline-none"
                id="first-name"
                type="text"
                placeholder="First Name"
                required
                v-model="form.user.forename"
              />
            </div>
            <div class="w-full px-3 mb-6 md:mb-0 md:w-1/2">
              <input
                class="appearance-none bg-transparent border-b py-6 w-full text-gray-900 mr-3 px-1 leading-relaxed focus:outline-none"
                id="last-name"
                type="text"
                placeholder="Last Name"
                required
                v-model="form.user.surname"
              />
            </div>
          </div>
          <div class="flex flex-wrap lg:mx-8 mb-6">
            <div class="w-full px-3 mb-6 md:mb-0">
              <input
                class="appearance-none bg-transparent border-b py-6 w-full text-gray-900 mr-3 px-1 leading-relaxed focus:outline-none"
                id="email"
                type="text"
                placeholder="email"
                required
                v-model="form.user.email"
                autocomplete="false"
              />
            </div>
          </div>
          <p class="lg:mx-12 font-bold text-md">Company Details</p>
          <div class="flex flex-wrap lg:mx-8 mb-6">
            <div class="w-full px-3 mb-6 md:mb-0 md:w-1/2">
              <input
                class="appearance-none bg-transparent border-b py-6 w-full text-gray-900 mr-3 px-1 leading-relaxed focus:outline-none"
                id
                type="text"
                placeholder="Company Name"
                required
                v-model="form.company.name"
              />
            </div>
            <div class="w-full px-3 mb-6 md:mb-0 md:w-1/2">
              <input
                class="appearance-none bg-transparent border-b py-6 w-full text-gray-900 mr-3 px-1 leading-relaxed focus:outline-none"
                type="text"
                placeholder="Company Phone"
                required
                v-model="form.company.phone"
              />
            </div>
          </div>
          <div class="flex flex-wrap lg:mx-8 mb-6">
            <div class="w-full px-3 mb-6 md:mb-0">
              <input
                class="appearance-none bg-transparent border-b py-6 w-full text-gray-900 mr-3 px-1 leading-relaxed focus:outline-none"
                type="url"
                placeholder="https://example.com"
                pattern="https://.*"
                required
                v-model="form.company.website"
              />
            </div>
          </div>
          <!-- Image -->
          <p class="flex flex-wrap xl:flex-no-wrap font-bold text-md lg:mx-12">Existing logo</p>
          <div class="flex flex-wrap xl:flex-no-wrap w-full px-8 mt-8 mb-8 h-40 mx-auto">
            <div class="w-full xl:w-1/2 mb-4">
              <img :src="this.$page.auth.user.company.logo" style="height: 110px" />
            </div>
            <div class="w-full xl:w-1/2">
              <div class="flex flex-col justify-center">
                <div class="w-full relative" v-if="form.company.logo != null">
                  <img :src="this.liveImage" style="height: 110px" />
                  <button
                    type="button"
                    class="absolute top-0 right-0 h-8 w-8"
                    @click="deleteImage()"
                  >
                    <i class="fa fa-times"></i>
                  </button>
                </div>
                <div v-else class="w-full">
                  <file-input v-model="form.company.logo" accept="image/*" />
                </div>
              </div>
            </div>
          </div>
          <!-- buttons -->
          <div class="flex justify-center w-full mt-32 xl:mt-0">
            <button
              type="button"
              class="ii--button-red w-1/2 lg:w-1/4 px-3 rounded-lg"
              @click="updateDetails"
            >Save Changes</button>
          </div>
        </div>

        <div
          class="w-full lg:w-3/4 bg-white mt-8 mb-8 px-2 py-4 lg:px-16 lg:pt-4 lg:pb-4"
          v-if="isVisible"
        >
          <p class="font-bold mt-4 lg:mt-12 text-base">Change Password</p>
          <form id="update--password" class="flex flex-wrap mt-4 mb-4">
            <div class="flex flex-col w-1/2">
              <label for="password">Password:</label>
              <input
                placeholder="Password"
                class="ii--form-input text-black mb-4 focus:outline-none"
                name="password"
                type="password"
                autocapitalize="off"
                v-model="update.password"
                id="password"
                required
                aria-required="true"
              />
            </div>
            <div class="flex flex-col w-1/2">
              <label for="confirm">Confirm Password:</label>
              <input
                placeholder="Confirm Password"
                class="ii--form-input text-black mb-4 focus:outline-none"
                name="confirm"
                autocapitalize="off"
                type="password"
                v-model="update.confirm"
                id="confirm"
                required
                aria-required="true"
              />
            </div>
            <div class="flex justify-end">
              <p class="text-red-500 text-xs italic text-right">Minimum of 8 characters.</p>
            </div>
            <div class="flex justify-center w-full mt-4">
              <input
                type="button"
                @click="updatePassword()"
                class="ii--button-red w-1/2 lg:w-1/3 px-3 rounded-lg"
                value="Update Password"
              />
            </div>
          </form>
        </div>

        <div
          class="w-full lg:w-3/4 bg-white mt-8 mb-8 px-2 py-4 lg:px-16 lg:pt-4 lg:pb-8"
          v-if="isVisible"
        >
          <p class="font-bold mt-4 lg:mt-12 text-md">Team Members</p>
          <!-- table -->
          <table class="hidden lg:table table-striped my-4 w-full">
            <thead class="bg-dashboard-top text-black">
              <th>Name</th>
              <th>Email</th>
              <th>Auth Level</th>
              <th>Action</th>
            </thead>
            <tbody class="text-black">
              <tr
                v-for="(member, index) in teamMembers"
                :key="index"
                style="height: 55px;line-height:30px;"
              >
                <td>{{member.name}}</td>
                <td>{{member.email}}</td>
                <td>{{member.roles[0].description }}</td>
                <td>
                  <inertia-link
                    :href="$route('delete.team.member', member.id)"
                    method="delete"
                    class="bg-cherryred-primary text-white py-2 px-6 font-bold -mr-2 rounded-lg"
                  >Delete</inertia-link>
                </td>
              </tr>
            </tbody>
          </table>

          <div
            class="flex flex-col lg:hidden mt-2"
            v-for="(member, index) in teamMembers"
            :key="index"
          >
            <p>
              <span class="font-bold">Name:</span>
              {{ member.name}}
            </p>
            <p>
              <span class="font-bold">Email:</span>
              {{ member.email }}
            </p>
            <p>
              <span class="font-bold">Role:</span>
              {{member.roles[0].description }}
            </p>
            <inertia-link
              :href="$route('delete.team.member', member.id)"
              method="delete"
              class="bg-cherryred-primary w-1/2 mx-auto text-white py-2 px-6 font-bold mx-4 text-center mt-1 rounded-lg"
            >Delete</inertia-link>
            <hr class="border border-black my-2" />
          </div>

          <!-- team member modal -->
          <div class="flex justify-center w-full">
            <button
              type="button"
              class="ii--button-red w-1/2 lg:w-1/3 rounded-lg"
              @click="showTeamModal = !showTeamModal"
            >Add Team Member</button>
          </div>
        </div>
      </div>
      <div
        class="fixed inset-0 w-full h-screen flex items-center justify-center bg-modal"
        v-show="showTeamModal"
      >
        <team-modal class="bg-white shadow-lg p-8" @close="showTeamModal = !showTeamModal" />
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout";
import FileInput from "@/Shared/FileInput";
import TeamModal from "./AddTeamMember";

export default {
  name: "",
  components: {
    Layout,
    FileInput,
    TeamModal
  },
  props: {},
  data() {
    return {
      interview: {},
      teams: {},
      credits: {},
      showTeamModal: false,
      teamMembers: [],
      form: {
        user: {
          forename: "",
          surname: "",
          email: ""
        },
        company: {
          name: this.$page.auth.user.company.name,
          website: this.$page.auth.user.company.website,
          logo: null,
          phone: this.$page.auth.user.company.phone
        }
      },
      update: {
        password: null,
        confirm: null
      },
      liveImage: null
    };
  },
  mounted() {
    this.getCompanyInterviews();
    this.getTeamMembers();
    this.populateForm();
    this.redirectTo();
  },
  methods: {
    getCompanyInterviews() {
      axios.get(this.$route("company.interviews")).then(response => {
        this.interview = response.data;
      });
    },
    newLogo() {
      if (this.form.company.logo != null) {
        this.liveImage = URL.createObjectURL(this.form.company.logo);
      }
    },
    deleteImage() {
      this.liveImage = null;
      this.form.company.logo = null;
    },
    updateDetails() {
      const config = { headers: { "Content-Type": "multipart/form-data" } };

      let data = new FormData();
      data.append("company", this.$page.auth.user.company.id);
      data.append("user", this.$page.auth.user.id);
      data.append("company_name", this.form.company.name);
      data.append("company_phone", this.form.company.phone);
      data.append("forename", this.form.user.forename);
      data.append("surname", this.form.user.surname);
      data.append("email", this.form.user.email);

      if (this.validUrl(this.form.company.website)) {
        data.append("company_website", this.form.company.website);
      } else {
        data.append(
          "company_website",
          this.buildUrl(this.form.company.website)
        );
      }

      if (this.liveImage != null) {
        data.append("logo", new Blob([this.form.company.logo]));
      }

      this.$inertia
        .post(this.$route("update.details"), data, config)
        .then(() => {
          if (this.$page.flash.message) {
          }
        });
    },
    updatePassword() {
      if (this.update.password == this.update.confirm) {
        if (this.update.password.length < 8 && this.update.confirm.length < 8) {
          alertify.error("Password is under 8 characters");
        } else {
          this.$inertia
            .post(this.$route("password.update"), {
              id: this.$page.auth.user.id,
              password: this.update.password,
              password_confirmation: this.update.confirm
            })
            .then(response => {
              this.update = {};
            });
        }
      } else {
        alertify.error("Passwords do not match");
      }
    },
    populateForm() {
      this.form.user.forename = this.$page.auth.user.first_name;
      this.form.user.surname = this.$page.auth.user.last_name;
      this.form.user.email = this.$page.auth.user.email;
      //   this.form.company.logo = this.$page.auth.user.company.logo;
      this.form.company.name = this.$page.auth.user.company.name;
      this.form.company.phone = this.$page.auth.user.company.phone;
      this.form.company.website = this.$page.auth.user.company.website;
    },
    validUrl(str) {
      let pattern = new RegExp(
        "^" +
          // protocol identifier (optional)
          // short syntax // still required
          "(?:(?:(?:https?|ftp):)?\\/\\/)" +
          // user:pass BasicAuth (optional)
          "(?:\\S+(?::\\S*)?@)?" +
          "(?:" +
          // IP address exclusion
          // private & local networks
          "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
          "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
          "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
          // IP address dotted notation octets
          // excludes loopback network 0.0.0.0
          // excludes reserved space >= 224.0.0.0
          // excludes network & broadcast addresses
          // (first & last IP address of each class)
          "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
          "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
          "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
          "|" +
          // host & domain names, may end with dot
          // can be replaced by a shortest alternative
          // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
          "(?:" +
          "(?:" +
          "[a-z0-9\\u00a1-\\uffff]" +
          "[a-z0-9\\u00a1-\\uffff_-]{0,62}" +
          ")?" +
          "[a-z0-9\\u00a1-\\uffff]\\." +
          ")+" +
          // TLD identifier name, may end with dot
          "(?:[a-z\\u00a1-\\uffff]{2,}\\.?)" +
          ")" +
          // port number (optional)
          "(?::\\d{2,5})?" +
          // resource path (optional)
          "(?:[/?#]\\S*)?" +
          "$",
        "i"
      );
      return !!pattern.test(str);
    },
    buildUrl(str) {
      let url = str;
      let regex = /^(http|https)/;
      if (url.length > 3 && !url.match(regex)) {
        return "https://" + str;
      }
    },
    redirectTo() {
      if (
        this.$page.auth.user.company.phone == null ||
        this.$page.auth.user.company.website == null
      ) {
        this.$inertia.visit(this.$route("dashboard"));
      }
    },
    getTeamMembers() {
      axios
        .get(
          this.$route("company.team.members", this.$page.auth.user.company.id)
        )
        .then(resp => {
          this.teamMembers = resp.data;
        });
    }
  },
  computed: {
    isVisible() {
      if (
        this.$page.auth.user.role == "superadministrator" ||
        this.$page.auth.user.role == "manager"
      ) {
        return true;
      }
      return false;
    }
  },

  watch: {
    "form.company.logo": {
      handler() {
        this.newLogo();
      },
      deep: true
    },
    "$page.errors": {
      handler() {
        if (Object.keys(this.$page.errors).length > 0) {
          let errorName = Object.keys(this.$page.errors);
          alertify.error(_.startCase(errorName) + " error");
        }
      },
      deep: true
    },
    "$page.flash": {
      handler() {
        alertify.success(this.$page.flash.success);
      },
      deep: true
    },
    "form.company.website": {
      handler(val) {
        // console.log(this.buildUrl(val));
      }
    }
  }
};
</script>
