<template>
  <div>
    <p class="font-bold text-lg">Rate Competencies</p>
    <p class="text-sm mt-2">Please click on a stars below to rate each competency for this candidate</p>
    <div class="flex flex-col mt-8" v-for="(skill, index) in this.skill" :key="index">
      <div class="flex">
        <p class="text-md font-semibold">{{ skill.name }}</p>
      </div>
      <div class="flex mt-4">
        <star-rating
          v-model="skill.rating"
          :increment="0.5"
          :read-only="false"
          :show-rating="true"
          :star-size="40"
          :rounded-corners="true"
          inactive-color="#091b2b"
          activeColor="#ffdf00"
          border-color="#ffdf00"
          :border-width="4"
          class="-mt-1 mb-2 lg:mb-6"
          @rating-selected="skillsRating(skill.name)"
        ></star-rating>
      </div>
    </div>
  </div>
</template>

<script>
import StarRating from "vue-star-rating";
import { filter } from "minimatch";

export default {
  name: "SkillsRating",
  components: {
    StarRating
  },
  props: {
    skills: Array,
    candidate: Number,
    interview: Number,
    company: Number
  },
  beforeMount() {
    this.setSkills();
  },
  mounted() {
    this.getSkills();
  },
  data() {
    return {
      skill: []
    };
  },
  methods: {
    skillsRating(skill) {
      const rating = _.find(this.skill, s => {
        if (s.name == skill) {
          return s.rating;
        }
      });
      let data = new FormData();
      data.append("rating", JSON.stringify(rating));
      data.append("user", this.$page.auth.user.id);
      data.append("interview", this.interview);
      data.append("candidate", this.candidate);
      data.append("company", this.company);
      axios.post(this.$route("candidate.skill.rating"), data).then(response => {
        if (response.data.success) {
          alertify.success(response.data.message);
        } else {
          alertify.error(response.data.message);
        }
      });
    },
    setSkills() {
      _.forEach(this.skills, val => {
        this.skill.push({
          name: val.skill,
          rating: 0
        });
      });
    },
    getSkills() {
      let data = new FormData();
      data.append("candidate", this.candidate);
      data.append("user", this.$page.auth.user.id);
      axios
        .post(this.$route("get.candidate.skill.rating"), data)
        .then(response => {
          _.forEach(this.skill, (s, i) => {
            let skillData = response.data.data;
            _.find(skillData, (d, i) => {
              if (d.name == s.name) {
                s.rating = d.rating;
              }
            });
          });
        });
    }
  },
  computed: {},
  watch: {}
};
</script>
