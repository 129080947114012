<template>
  <layout>
    <div class="flex flex-auto">
      <!-- Left Col -->
      <div class="hidden md:flex bg-navy-primary w-full md:w-4/12 lg:w-2/12 mr-3 h-auto">
        <div class="w-full py-8 px-8">
          <inertia-link
            :href="$route('candidate.profile', this.candidate.id)"
            class="bg-yellow text-black flex items-center justify-between text-md font-semibold w-full py-2 rounded-lg"
          >
            <i class="fa fa-chevron-left ml-4 text-sm"></i>
            <span class="px-2">Back to candidate</span>
          </inertia-link>
          <div class="flex items-center my-10">
            <div class="w-1/3 mr-4">
              <p class="text-md text-white leading-relaxed">{{ this.candidate.forename}}</p>
              <p class="text-lg text-white leading-relaxed font-bold">{{ this.candidate.surname }}</p>
            </div>
            <div class="w-2/3 mr-4">
              <img
                class="border-4 border-white rounded-full w-16 h-16 float-right"
                :src="this.candidate.photo"
              />
            </div>
          </div>
          <hr class="border-gray-100 opacity-25 my-10" />
          <div class="flex flex-col justify-start">
            <div class="py-4">
              <p class="text-cherryred-primary text-sm leading-tight font-bold">Email</p>
              <p class="text-white text-xs leading-snug mt-2">{{ this.candidate.email }}</p>
            </div>
            <div class="py-4">
              <p class="text-cherryred-primary text-sm leading-tight font-bold">Phone Number:</p>
              <p class="text-white text-xs leading-snug mt-2">{{ this.candidate.phoneNumber }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- RIGHT COL -->
      <div class="lg:flex lg:flex-grow bg-background hidden">
        <div class="mx-8 my-8 w-full">
          <div class="bg-darknavy-primary w-full flex justify-center md:justify-start">
            <p class="text-3xl md:ml-4 float-left my-4">Candidate Facial Recognition Report</p>
          </div>
          <div class="flex flex-col justify-center" v-for="(c,index) in analysis" :key="index">
            <div class="flex px-6 py-6 bg-white text-black my-4">
              <div class="w-1/2 mr-4 mt-8">
                <img :src="c.still" class="border-4 border-darknavy-primary" />
              </div>
              <div
                class="flex flex-col w-1/2 w-full justify-center content-center items-center p-10"
              >
                <div class="w-full -mt-4 text-right mb-4">
                  <div v-if="c.timecode">
                    <p class="font-bold text-3xl">{{ c.timecode }}</p>
                  </div>
                  <div v-else>
                    <p class="font-bold text-3xl">Average</p>
                  </div>
                  <hr class="w-full border-2 border-black" />
                </div>

                <div class="w-full mt-2">
                  <progress-bar :analysis="c.analysis" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout";
import ProgressBar from "../../components/ProgressBar";

export default {
  name: "CandidateAnalysis",
  components: {
    Layout,
    ProgressBar
  },
  props: {
    analysis: Object,
    candidate: Object
  },
  data() {
    return {};
  },
  methods: {
    convertTimeCode(val) {
      return moment(val).format("HH:mm:ss");
    }
  },
  computed: {
    numberOfCols() {
      return "w-1/" + Object.keys(this.analysis).length;
    }
  },
  watch: {}
};
</script>
