<script>
export default {
  watch: {
    "$page.errors": {
      handler() {
        if (Object.keys(this.$page.errors).length > 0) {
          let error = Object.keys(this.$page.errors);
          alertify.error(_.startCase(error) + " error");
        }
      },
      deep: true
    }
  }
};
</script>
