<template>
  <!-- dropzone -->
  <form id="file--upload" enctype="multipart/form-data">
    <vue-dropzone
      ref="myVueDropzone"
      id="dropzone"
      :options="dropzoneOptions"
      @vdropzone-success="dropzoneOnSuccess"
    ></vue-dropzone>
  </form>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  name: "FileUpload",
  components: {
    vueDropzone: vue2Dropzone
  },
  props: {
    url: {
      type: String,
      required: true
    },
    maxFiles: {
      type: String,
      default: 1
    },
    maxFileSize: {
      type: String,
      default: 2
    },
    fileTypes: {
      type: String,
      default:
        "image/jpg, image/jpeg, application/pdf, text/csv, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    },
    interview: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dropzoneOptions: {
        url: this.url,
        maxFilesize: this.maxFileSize,
        maxFiles: this.maxFiles,
        acceptedFiles: this.fileTypes,
        dictDefaultMessage: "<i class='fa fa-5x fa-upload'></i>",
        headers: {
          "X-CSRF-TOKEN": document.head.querySelector('meta[name="csrf-token"]')
            .content
        },
        addRemoveLinks: true,
        params: {
          interview_id: this.interview.id,
          company_id: this.user.company.id,
          company_name: this.user.company.name,
          user_id: this.user.id
        }
      }
    };
  },
  methods: {
    dropzoneOnSuccess(file, response) {
      if (response.success) {
        alertify.success(response.message);
        this.$emit("uploaded");
      }
    }
  },
  computed: {},
  watch: {}
};
</script>

<style scoped>
</style>
