<template>
  <layout>
    <div class="flex justify-center w-full">
      <form class="py-16 mx-auto px-40 bg-gray-200 mt-10 max-w-6xl" @submit.prevent="submit">
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full px-3 mb-6 md:mb-0 md:w-1/2">
            <input
              class="appearance-none bg-transparent border-b py-6 w-full text-gray-900 mr-3 py-1 px-2 leading-relaxed focus:outline-none"
              id="first-name"
              type="text"
              placeholder="First Name"
              autofocus
              required
              v-model="form.forename"
            />
          </div>
          <div class="w-full px-3 mb-6 md:mb-0 md:w-1/2">
            <input
              class="appearance-none bg-transparent border-b py-6 w-full text-gray-900 mr-3 py-1 px-2 leading-relaxed focus:outline-none"
              id="last-name"
              type="text"
              placeholder="Last Name"
              requried
              v-model="form.surname"
            />
          </div>
        </div>
        <div class="mb-6">
          <input
            class="appearance-none bg-transparent border-b py-6 w-full text-gray-900 mr-3 py-1 px-2 leading-relaxed focus:outline-none"
            id="password"
            type="password"
            placeholder="Password"
            required
            aria-required="true"
            v-model="form.password"
            autocomplete="false"
          />
          <p class="text-red-500 text-xs italic text-right">Minimum of 8 characters.</p>
        </div>

        <div class="mb-6">
          <input
            class="bg-cherryred-primary py-4 px-8 w-full"
            value="Update Account"
            type="button"
            @click="updateUser()"
          />
        </div>
      </form>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout";

export default {
  name: "",
  components: {
    Layout
  },
  props: {
    User: Object,
    Errors: Object
  },
  data() {
    return {
      form: {
        forename: "",
        surname: "",
        password: "",
        user_id: this.$page.auth.user.id,
        email: this.$page.auth.user.email
      },
      sending: false
    };
  },
  methods: {
    updateUser() {
      this.sending = true;
      let data = new FormData();
      data.append("user_id", this.form.user_id);
      data.append("forename", this.form.forename);
      data.append("surname", this.form.surname);
      data.append("email", this.form.email);
      data.append("password", this.form.password);
      this.$inertia.post("/update-account", data).then(() => {
        this.sending = false;
      });
    }
  },
  computed: {},
  watch: {}
};
</script>
