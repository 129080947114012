<template>
  <inertia-link
    :href="$route(this.$routeLink)"
    class="bg-cherryred-primary text-white rounded-lg py-2 px-6 font-bold uppercase"
  >{{ this.linkText }}</inertia-link>
</template>

<script>
export default {
  name: "DashboardLink",
  props: {
    routeText: String,
    routeValue: String | Number,
    linkText: String
  },
  computed: {
    routeLink() {
      return this.$routeText + "," + this.rotueValue;
    }
  }
};
</script>
