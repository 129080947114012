<template>
  <transition type="modal-fade">
    <div class="modal w-full h-full lg:h-auto lg:w-1/2 mt-10 mb-10 mx-auto top-0 left-0">
      <div class="modal-header flex justify-between">
        <div>
          <p class="text-black ii--subheading-text my-8">Manually Invite Candidates</p>
        </div>
        <button type="button" class="text-black float-right" @click="close">
          <i class="fa fa-2x fa-times"></i>
        </button>
      </div>
      <div class="modal-body flex flex-col justify-center">
        <p class="text-black text-center text-xl lg:text-2xl my-8">Enter a candidate</p>
        <form
          method="post"
          id="invite--candidate"
          class="flex flex-col justify-center mx-auto w-full mb-8 px-8"
        >
          <input
            type="text"
            id="candidate--forename"
            class="ii--form-input-full text-black"
            v-model="candidate.forename"
            placeholder="Forename"
          />
          <input
            type="text"
            id="candidate--surname"
            class="ii--form-input-full text-black"
            v-model="candidate.surname"
            placeholder="Surname"
          />
          <input
            type="email"
            id="candidate--email"
            class="ii--form-input-full text-black"
            v-model="candidate.email"
            placeholder="Email"
          />
          <div class="flex justify-center w-full">
            <button
              type="button"
              class="ii--button-red w-full lg:w-2/5 my-8 rounded-lg"
              @click="addCandidateToInterview()"
            >Invite</button>
          </div>
        </form>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "InviteCandidates",
  props: {
    errors: Object,
    interview: Object
  },
  data() {
    return {
      candidate: {
        forename: "",
        surname: "",
        email: "",
        company_id: this.interview.company_id,
        interview_id: this.interview.id,
        user_id: this.interview.user_id
      }
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    addCandidateToInterview() {
      let id = this.interview.id;
      axios.post("/interview/invite-candidates", this.candidate).then(resp => {
        if (resp.status == 200) {
          this.$emit("close");
          this.candidate = "";
          alertify.success(resp.data.message, "success");
          this.$inertia.visit(
            this.$route("show.candidates", this.interview.id)
          );
        }
      });
    }
  }
};
</script>
