<template>
  <layout title="Complete Setup">
    <div class="flex flex-col lg:h-screen">
      <div class="flex justify-center mb-5">
        <div class="flex flex-col items-center">
          <p class="ii--header-text mt-10 mb-4">Start your FREE trial</p>
          <p class="text-md leading-loose">Fill in your details in below to begin</p>
        </div>
      </div>
      <div class="flex justify-center mx-4">
        <div
          v-show="step === 1"
          class="bg-white mt-10 mx-auto w-full md:w-3/4 xl:w-3/4 px-2 md:px-16"
        >
          <div class="flex justify-center font-semibold text-md mt-8 xl:mt-4 mb-12">
            <p class="text-black text-center w-full">Your Details</p>
          </div>
          <div
            class="flex justify-center items-center content-center flex-wrap mx-auto xl:w-full mb-6 px-3"
          >
            <input type="hidden" aria-hidden="true" v-model="form.user_id" />
            <input type="hidden" aria-hidden="true" v-model="form.company_id" />
            <div class="flex justify-center items-center content-center flex-wrap md:w-full mb-6">
              <div class="md:px-3 mb-6 md:mb-0 w-full md:w-1/2">
                <input
                  class="ii--form-input w-full text-black focus:outline-none"
                  id="first-name"
                  type="text"
                  placeholder="First Name"
                  v-model="form.first"
                  required
                  autofocus
                  aria-required="true"
                  autocomplete="off"
                />
              </div>
              <div class="md:px-3 mb-6 md:mb-0 w-full md:w-1/2">
                <input
                  class="ii--form-input w-full text-black focus:outline-none"
                  id="last-name"
                  type="text"
                  placeholder="Last Name"
                  v-model="form.last"
                  required
                  aria-required="true"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="flex justify-center items-center content-center flex-wrap md:w-full mb-6">
              <div class="md:px-3 mb-6 md:mb-0 w-full md:w-1/2">
                <input
                  class="ii--form-input w-full text-black focus:outline-none"
                  id="email"
                  type="email"
                  placeholder="Email Address"
                  required
                  aria-required="true"
                  v-model="form.email"
                  tabindex="-1"
                  autocomplete="off"
                />
              </div>
              <div class="md:px-3 mb-6 md:mb-0 w-full md:w-1/2">
                <div class="flex flex-col mt-6">
                  <input
                    class="ii--form-input w-full text-black focus:outline-none"
                    id="password"
                    type="password"
                    placeholder="Password"
                    required
                    aria-required="true"
                    v-model="form.password"
                    autocomplete="off"
                    minlength="8"
                  />
                  <p class="text-red-500 text-xs italic text-right mt-2">Minimum of 8 characters.</p>
                </div>
              </div>
            </div>
            <input
              type="button"
              @click="nextStep()"
              class="ii--button-red mt-12 w-full lg:w-1/5 rounded-lg"
              value="continue"
            />
          </div>
        </div>
        <div v-show="step === 2" class="bg-white py-16 px-12 mt-10 w-full xl:px-32 xl:w-3/4">
          <div
            class="flex flex-wrap justify-center w-full font-semibold leading-tight text-md mt-4 mb-6"
          >
            <p class="text-black text-center w-full">Your Company Details</p>
          </div>
          <div class="flex flex-wrap justify-center md:w-full mb-6">
            <div class="md:px-3 mb-6 md:mb-0 w-full md:w-1/2 lg:w-full xl:w-1/2">
              <input
                class="ii--form-input w-full text-black focus:outline-none"
                id="website"
                type="website"
                placeholder="https://www.example.com - please note this must be a valid url to progress"
                required
                aria-required="true"
                autofocus
                v-model="form.company.website"
                autocomplete="off"
              />
            </div>
            <div class="md:px-3 mb-6 md:mb-0 w-full md:w-1/2 lg:w-full xl:w-1/2">
              <input
                class="ii--form-input w-full text-black focus:outline-none"
                id="phone--number"
                type="tel"
                placeholder="Phone Number"
                required
                aria-required="true"
                v-model="form.company.phone"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="flex flex-wrap justify-center md:w-full mb-6">
            <div class="flex mx-auto w-full justify-center">
              <div class="w-full relative" v-if="form.company.logo != null">
                <img :src="this.liveImage" style="height: 110px" class="mx-auto" />
                <button
                  type="button"
                  class="absolute text-black top-0 right-0 h-8 w-8"
                  @click="deleteImage()"
                >
                  <i class="fa fa-times"></i>
                </button>
              </div>
              <div v-else class="w-full">
                <file-input
                  v-model="form.company.logo"
                  type="file"
                  accept="image/*"
                  label="Logo"
                  title="Upload a Logo"
                />
              </div>
            </div>
          </div>
          <div class="flex md:flex-wrap justify-center mt-12 -mx-2">
            <input
              type="button"
              class="bg-cherryred-primary uppercase py-4 font-bold w-full lg:w-1/5 rounded-lg mx-1"
              value="previous"
              @click="previousStep()"
            />
            <input
              type="button"
              class="bg-cherryred-primary uppercase py-4 font-bold w-full lg:w-1/5 rounded-lg mx-1"
              value="Finish"
              @click="updateAccount()"
            />
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout";
import FileInput from "@/Shared/FileInput";

export default {
  name: "",
  components: {
    Layout,
    FileInput
  },
  props: {},
  remember: ["form"],
  data() {
    return {
      step: 1,
      form: {
        first: this.$page.auth.user.first_name,
        last: this.$page.auth.user.last_name,
        email: this.$page.auth.user.email,
        password: "",
        user_id: this.$page.auth.user.id,
        company_id: this.$page.auth.user.company.id,
        company: {
          website: this.$page.auth.user.company.website,
          logo: this.$page.auth.user.company.logo,
          phone: this.$page.auth.user.company.phone
        }
      },
      liveImage: this.$page.auth.user.company.logo || null
    };
  },
  methods: {
    updateAccount() {
      let errors = 0;
      let data = new FormData();
      data.append("user_id", this.form.user_id);
      data.append("forename", this.form.first);
      data.append("surname", this.form.last);
      data.append("email", this.form.email);
      data.append("password", this.form.password);
      data.append("company_id", this.form.company_id);
      //   data.append("logo", this.form.company.logo);
      //   data.append("phone", this.form.company.phone);

      //   if (this.form.company.website == null) {
      //     errors++;
      //     alertify.error("Please enter a website");
      //   } else {
      //     console.log(this.validUrl(this.form.company.website));

      //   }

      if (this.validUrl(this.form.company.website)) {
        data.append("website", this.form.company.website);
      } else {
        data.append("website", this.buildUrl(this.form.company.website));
      }

      if (this.form.company.logo == null) {
        errors++;
        alertify.error("Please upload a logo");
      } else {
        data.append("logo", this.form.company.logo);
      }

      if (this.form.company.phone == null) {
        errors++;
        alertify.error("Please enter a contact number");
      } else {
        data.append("phone", this.form.company.phone);
      }

      if (errors === 0) {
        this.$inertia.post(this.$route("update.user"), data);
      } else {
        alertify.error("You have errors on your form");
      }
    },
    nextStep() {
      if (this.form.password.length < 8) {
        alertify.error("Please choose a longer password");
      } else {
        this.step++;
      }
    },
    previousStep() {
      this.step--;
    },
    newLogo() {
      if (this.form.company.logo != null) {
        this.liveImage = URL.createObjectURL(this.form.company.logo);
      }
    },
    deleteImage() {
      this.liveImage = null;
      this.form.company.logo = null;
    },
    validUrl(str) {
      let pattern = new RegExp(
        "^" +
          // protocol identifier (optional)
          // short syntax // still required
          "(?:(?:(?:https?|ftp):)?\\/\\/)" +
          // user:pass BasicAuth (optional)
          "(?:\\S+(?::\\S*)?@)?" +
          "(?:" +
          // IP address exclusion
          // private & local networks
          "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
          "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
          "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
          // IP address dotted notation octets
          // excludes loopback network 0.0.0.0
          // excludes reserved space >= 224.0.0.0
          // excludes network & broadcast addresses
          // (first & last IP address of each class)
          "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
          "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
          "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
          "|" +
          // host & domain names, may end with dot
          // can be replaced by a shortest alternative
          // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
          "(?:" +
          "(?:" +
          "[a-z0-9\\u00a1-\\uffff]" +
          "[a-z0-9\\u00a1-\\uffff_-]{0,62}" +
          ")?" +
          "[a-z0-9\\u00a1-\\uffff]\\." +
          ")+" +
          // TLD identifier name, may end with dot
          "(?:[a-z\\u00a1-\\uffff]{2,}\\.?)" +
          ")" +
          // port number (optional)
          "(?::\\d{2,5})?" +
          // resource path (optional)
          "(?:[/?#]\\S*)?" +
          "$",
        "i"
      );
      return !!pattern.test(str);
    },
    buildUrl(str) {
      let url = str;
      let regex = /^(http|https)/;
      if (url.length > 3 && !url.match(regex)) {
        return "https://" + str;
      }
    }
  },
  computed: {},
  watch: {
    "form.company.logo": {
      handler() {
        this.newLogo();
      },
      deep: true
    },
    "$page.errors": {
      handler() {
        if (Object.keys(this.$page.errors).length > 0) {
          let errorName = Object.keys(this.$page.errors);
          alertify.error(_.startCase(errorName) + " error");
        }
      },
      deep: true
    }
  }
};
</script>
