<template>
  <div>Time Remaining: {{ current }}</div>
</template>

<script>
export default {
  name: "Timer",
  components: {},
  props: {
    interval: {
      type: Number,
      default: 1
    },
    initial: {
      type: Number
    },
    start: {
      Type: Boolean,
      default: false
    }
  },
  created() {
    setInterval(this.onInterval, this.interval * 1000);
  },
  data() {
    return {
      current: this.initial,
      timerInterval: null
    };
  },
  methods: {
    onInterval() {
      this.current = this.current -= this.interval;
      if (this.current <= 0) {
        clearInterval(this.timerInterval);
        this.current = 0;
      }
    }
  }
};
</script>
