<template>
  <div class="flex flex-col justify-center content-center items-center mx-auto my-8">
    <video id="snapshot" playsinline ref="videoPlayer" class="video-js vjs-fluid vjs-default-skin"></video>
    <div class="-mt-10 lg:-mt-24 z-50">
      <button
        type="button"
        class="rounded-full w-8 h-8 lg:w-16 lg:h-16 border-2 border-white bg-ii"
        @click="takeSnapshot()"
      >
        <i class="fas md:fa-2x fa-camera text-white" />
      </button>
    </div>
  </div>
</template>

<script>
import "video.js/dist/video-js.css";
import "videojs-record/dist/css/videojs.record.css";

import "webrtc-adapter";
import RecordRTC from "recordrtc";

import videojs from "video.js";
import Record from "videojs-record/dist/videojs.record.js";

export default {
  name: "InterviewCaptureImage",
  props: {
    candidate: "",
    interview: "",
    company: ""
  },
  data() {
    return {
      player: null,
      image: {
        name: null,
        file: null
      },
      options: {
        controls: false,
        width: 640,
        height: 480,
        plugins: {
          record: {
            image: true,
            audio: false,
            video: false,
            frameWidth: 640,
            frameHeight: 480
          }
        }
      }
    };
  },
  mounted() {
    // window.playerEvents = this;
    this.playerInitialize();
    this.playerSetupEvents();
    if (this.player.recordedData) {
      this.player()
        .record()
        .reset();
    }
  },
  methods: {
    playerInitialize() {
      this.player = videojs(this.$refs.videoPlayer, this.options);
      this.player.record().getDevice();
    },
    takeSnapshot() {
      this.player.record().start();
    },
    playerSetupEvents() {
      this.player.on("error", function() {});
      this.player.on("finishRecord", () => {
        let photo = this.player.recordedData;
        this.$emit("image-captured", photo);

        let data = new FormData();
        data.append("image", photo);
        data.append("candidate", this.candidate);
        axios.post("/image-captured", data);
      });
      this.player.on("retry", () => {});
    }
  },
  computed: {},
  watch: {}
};
</script>
