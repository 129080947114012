<template>
  <nav class="flex flex-col justify-center flex-wrap bg-gray-200">
    <div class="flex justify-center text-black mt-4">
      <span class="font-semibold text-xl bg-blue-200 p-12">Interview Intelligence</span>
    </div>
    <div class="block lg:hidden">
      <button
        class="flex items-center px-3 py-2 border rounded text-white border-blue-400 hover:text-white hover:border-white"
      >
        <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </button>
    </div>
    <div class="w-full block flex-grow lg:flex lg:items-center lg:w-auto px-20">
      <div class="text-sm lg:flex-grow text-md md:text-2xl text-right">
        <inertia-link
          class="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-white mx-3"
          href="/login"
          method="post"
        >Login</inertia-link>
        <inertia-link
          class="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-white mx-3"
          href="/register"
          method="post"
        >Register</inertia-link>
      </div>
      <!-- </div> -->
    </div>
  </nav>
</template>

<script>
export default {
  name: "Header",
  props: {},
  data() {
    return {};
  },
  methods: {},
  computed: {},
  watch: {}
};
</script>
