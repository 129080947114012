<template>
  <div class="pt-8 px-4">
    <view-candidates :current="interview.id" @filtered="updateFilter" />
    <hr class="border-gray-100 -mx-8 opacity-25 my-8" />
    <quick-invite :interview="interview" />
    <div v-if="isVisible">
      <hr class="border-gray-100 -mx-8 opacity-25 my-8" />
      <quick-settings :interview="interview" />
    </div>
  </div>
</template>

<script>
import QuickInvite from "./QuickInvite.vue";
import ViewCandidates from "./ViewCandidates.vue";
import QuickSettings from "./QuickSettings.vue";
export default {
  name: "Sidebar",
  components: {
    QuickInvite,
    ViewCandidates,
    QuickSettings
  },
  props: {
    interview: Object
  },
  data() {
    return {};
  },
  methods: {
    updateFilter(filter) {
      this.$emit("filter", filter);
    }
  },
  computed: {
    isVisible() {
      if (
        this.$page.auth.user.role == "superadministrator" ||
        this.$page.auth.user.role == "manager"
      ) {
        return true;
      }
      return false;
    }
  },
  watch: {}
};
</script>
