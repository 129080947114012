require('./bootstrap');
import {
    InertiaApp
} from '@inertiajs/inertia-vue'

import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.min.css';
alertify.set("notifier", "position", "top-right");
alertify.set('notifier', 'delay', 4);

import anime from 'animejs/lib/anime.es.js';
Vue.prototype.$anime = anime;

import Vue from 'vue'
import moment from 'moment';
Vue.prototype.$moment = moment;

import {
    ErrorToast
} from "./mixins/ErrorToast";

Vue.config.productionTip = false

Vue.filter('formatDate', function (value) {
    if (value) {
        return moment(String(value)).format('Do MMMM YYYY');
    }
});

Vue.filter('commentDate', (v) => {
    if (v) {
        return moment(String(v)).format('d/M/Y H:m:s');
    }
})

Vue.filter('invitedDate', (v) => {
    if (v) {
        return moment(String(v)).format('DD/M/Y');
    }
});

Vue.filter('ucfirst', (v) => {
    if (v) {
        return v.charAt(0).toUpperCase() + v.slice(1)
    }
})

Vue.filter('firstLetter', function (value) {
    if (value) {
        return value.slice(0, 1);
    }
})

Vue.filter('truncateText', (v) => {
    if (v) {
        return (v.length > 50) ? v.substr(0, 50) + ' ...' : v;
    }
})

Vue.filter('shortTitle', (v) => {
    if (v) {
        return (v.length > 30) ? v.substr(0, 30) + ' ...' : v;
    }
})

Vue.filter('timer', (t) => {
    if (t) {
        let timer = t * 1000
        return moment.duration(timer, 'minutes');
    }
})

// Vue.mixin({
//     watch: {
//         "$page.errors": {
//             handler() {
//                 if (Object.keys(this.$page.errors).length > 0) {
//                     let errorName = Object.keys(this.$page.errors);
//                     alertify.error(_.startCase(errorName) + " error");
//                 }
//             },
//             deep: true
//         }
//     }
// });

//ziggy route handler
Vue.prototype.$route = (...args) => route(...args).url()
Vue.use(InertiaApp)
let app = document.getElementById('app')
const files = require.context('./', true, /\.vue$/i)

new Vue({
    render: h => h(InertiaApp, {
        props: {
            initialPage: JSON.parse(app.dataset.page),
            // resolveComponent: name => import(`@/Pages/${name}`).then(module => module.default),
            resolveComponent: page => files(`./Pages/${page}.vue`).default,
        },
    }),

}).$mount(app)
