<template>
  <layout>
    <div class="h-screen">
      <div class="flex justify-center content-center items-center">
        <p class="text-center text-white text-2xl font-bold mt-6 mb-10">Oopss</p>
      </div>
      <div class="h-3/4 mx-8">
        <div
          class="flex flex-col justify-center content-center items-center bg-background mx-auto w-full h-full"
        >
          <p class="text-center text-white text-4xl font-bold mt-6 mb-10">{{ title }}</p>
          <p class="text-white text-xl text-center font-bold">{{ description }}</p>
        </div>
        <div class="bg-buttonLower w-full h-5 -mt-px"></div>
      </div>
    </div>
    <!-- <div class="flex flex-col justify-center items-center content-center bg-background h-screen">
      <p class="text-4xl font-bold text-black">{{ title }}</p>
      <br />
      <p class="text-2xl text-black">{{ description }}</p>
    </div>-->
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout";

export default {
  name: "Error",
  components: {
    Layout
  },
  props: {
    status: Number
  },
  computed: {
    title() {
      return {
        503: "503: Service Unavailable",
        500: "500: Server Error",
        404: "404: Page Not Found",
        403: "404: Forbidden"
      }[this.status];
    },
    description() {
      return {
        503: "Sorry, we are doing some maintenance. Please check back soon.",
        500: "Whoops, something went wrong on our servers.",
        404: "Sorry, the page you are looking for could not be found.",
        403: "Sorry, you are forbidden from accessing this page."
      }[this.status];
    }
  }
};
</script>
