<template>
  <div class="flex flex-col">
    <p class="font-medium text-xl mb-4">Quick Invite</p>
    <form name="quick--invite-candidate">
      <input
        class="shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
        placeholder="Forename"
        type="text"
        v-model="candidate.forename"
      />
      <input
        class="shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
        placeholder="Surname"
        type="text"
        v-model="candidate.surname"
      />
      <input
        class="shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
        placeholder="Email"
        type="email"
        v-model="candidate.email"
      />
      <button
        type="button"
        class="ii--button-red w-full rounded-lg"
        @click="addCandidateToInterview()"
      >Invite</button>
    </form>
  </div>
</template>

<script>
export default {
  name: "QuickInvite",
  props: {
    errors: Object,
    interview: Object
  },
  data() {
    return {
      candidate: {
        forename: "",
        surname: "",
        email: "",
        company_id: this.interview.company_id,
        interview_id: this.interview.id,
        user_id: this.interview.user_id
      }
    };
  },
  methods: {
    addCandidateToInterview() {
      let id = this.interview.id;
      axios
        .post(this.$route("invite.candidates"), this.candidate)
        .then(response => {
          if (response.status == 200) {
            alertify.success(response.data.message, "success");
            this.$inertia.visit(this.$route("show.candidates", id));
          }
        });
    }
  },
  computed: {},
  watch: {
    "$page.errors": {
      handler() {
        if (Object.keys(this.$page.errors).length > 0) {
          let error = Object.keys(this.$page.errors);
          alertify.error(_.startCase(error) + " error");
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped>
</style>
