<template>
  <layout>
    <div class="flex flex-auto h-screen">
      <!-- Left Col -->
      <div
        class="hidden md:flex bg-navy-primary w-full md:w-2/12 lg:w-2/12 mr-3 h-auto flex-shrink-0"
      >
        <div v-if="interviews.length > 0">
          <quick-interview :interviews="interviews" />
        </div>
        <div v-else class="p-12">
          <p>tips?</p>
        </div>
      </div>
      <!-- Right Col -->
      <div class="flex flex-grow bg-background h-auto md:h-auto justify-center text-black">
        <div class="w-3/4 mx-auto bg-white h-2/4 mt-8">
          <div class="flex flex-col mt-8 content-center items-center">
            <button class="mt-8 text-xl" @click="createInterview()">
              <p class="ii--header-text mx-auto mt-2 mb-4">Create new interview</p>
              <i class="fa fa-10x fa-plus-circle mx-auto text-green" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout";
import QuickInterview from "../../components/QuickInterview.vue";

export default {
  name: "interviewIndex",
  components: {
    Layout,
    QuickInterview
  },
  props: {
    interviews: Array
  },
  data() {
    return {
      createInterview() {
        this.$inertia.visit(this.$route("interview.create"));
      }
    };
  },
  methods: {},
  computed: {},
  watch: {}
};
</script>
