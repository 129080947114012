<template>
  <div class="flex justify-center">
    <form
      id="login--user"
      class="bg-white mx-auto w-full md:w-full lg:w-full xl:w-1/2 py-16 px-20 mb-6 md:mx-2"
      @submit.prevent="submit"
      @keydown.enter="login()"
    >
      <input
        placeholder="Email"
        class="ii--form-input-full text-black mb-4 focus:outline-none"
        id="email"
        type="email"
        name="email"
        required
        autofocus
        autocapitalize="off"
        v-model="form.email"
      />
      <input
        type="password"
        class="ii--form-input-full text-black mb-4 focus:outline-none"
        placeholder="Password"
        id="password"
        name="password"
        required
        v-model="form.password"
      />
      <label class="mt-6 select-none flex items-center" for="remember">
        <input id="remember" v-model="form.remember" class="mr-1" type="checkbox" />
        <span class="text-sm text-black">Remember Me</span>
      </label>
      <div class="flex flex-wrap items-center">
        <div class="w-full md:w-1/2 order-last md:order-first">
          <p class="text-navy-primary font-light text-xs opacity-75 mt-8 w-full">
            Forgot your password?
            <inertia-link class="font-bold" :href="$route('password.reset')">Click Here</inertia-link>
          </p>
        </div>
        <div class="w-full md:w-1/2 mx-auto">
          <input
            type="submit"
            @click="login()"
            class="ii--button-darknavy mt-10 px-16 float-right"
            value="Log In"
          />
        </div>
      </div>
    </form>
  </div>
</template>


<script>
export default {
  name: "Login",
  props: {},
  data() {
    return {
      form: {
        email: null,
        password: null,
        remember: null
      }
    };
  },
  methods: {
    login() {
      this.$inertia.post(this.$route("login.attempt"), {
        email: this.form.email,
        password: this.form.password,
        remember: this.form.remember
      });
    }
  },
  watch: {
    "$page.errors": {
      handler() {
        if (Object.keys(this.$page.errors).length > 0) {
          let error = Object.keys(this.$page.errors);
          let value = Object.values(this.$page.errors);
          let message = "Error: " + value[0];
          alertify.error(message);
        }
      }
    }
  }
};
</script>
