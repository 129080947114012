<template>
  <div>
    <div class="flex flex-wrap -mx-3 mb-6">
      <div
        class="w-full md:w-1/12 px-3 mb-6 md:mb-0 flex content-center items-center justify-center"
      >
        <i class="fa md:fa-2x fa-bars handle"></i>
      </div>
      <div
        class="w-full md:w-1/12 px-3 mb-6 md:mb-0 flex content-center items-center justify-center"
      >
        <v-select
          class="style-chooser"
          :options="selectOptions"
          :label="question.index"
          :searchable="false"
          :clearable="false"
          required
          :value="question.type.type"
        >
          <template slot="selected-option" slot-scope="selectOptions">
            <span class="fa md:fa-2x style-chooser" :class="selectOptions.icon"></span>
          </template>
          <template slot="option" slot-scope="option">
            <span class="fa md:fa-2x style-chooser" :class="option.icon"></span>
          </template>
        </v-select>
      </div>
      <div class="w-full md:w-9/12 px-3 mb-6 md:mb-0">
        <input
          class="ii--form-input-full focus: outline-none focus:bg-white"
          id="title"
          type="text"
          placeholder="Please enter your question here"
          name="question[][index]"
          v-model="question.title"
        />
      </div>
      <div
        class="w-full md:w-1/12 px-3 mb-6 md:mb-0 text-red-400 flex justify-center items-center content-center"
      >
        <i class="fa fa-trash" title="Remove Question" @click="removeQuestionInputs(index)"></i>
      </div>
    </div>
    {{ question }}
  </div>
</template>

<script>
import draggable from "vuedraggable";
import vSelect from "vue-select";

export default {
  name: "Question",
  components: {
    vSelect
  },
  props: {
    question: Object
  },
  data() {
    return {
      selectOptions: [
        {
          type: 0,
          icon: "fa-font"
        },
        {
          type: 1,
          icon: "fa-video"
        }
      ]
    };
  },
  methods: {}
};
</script>
