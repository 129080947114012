<template>
  <div class="flex flex-col">
    <p class="font-medium text-xl mb-4">View Candidates</p>
    <div class="my-4">
      <label
        for="interview--select"
        class="block tracking-wide text-white text-sm font-bold mb-4"
      >Select an Interview</label>
      <div class="relative">
        <select
          name="interview--select"
          class="block appearance-none w-full bg-white text-black py-2 px-4 pr-8 leading-tight"
          v-model="selected"
        >
          <option
            v-for="(interview, index) in interviews"
            :key="index"
            :value="interview.id"
            class="text-black"
          >{{ interview.title }}</option>
        </select>
      </div>
    </div>
    <div class="flex flex-col">
      <label for="all--candidates" class="text-white leading-relaxed text-md my-2">
        <input id="all" type="radio" name="candidates" checked @click="filterBy('all')"/>
        <span class="ml-2">All Candidates</span>
      </label>
      <label for="review" class="text-white leading-relaxed text-md my-2">
        <input id="review" type="radio" name="candidates" @click="filterBy('complete')"/>
        <span class="ml-2">Completed Interviews</span>
      </label>
      <label for="shortlisted" class="text-white leading-relaxed text-md my-2">
        <input id="shortlisted" type="radio" name="candidates" @click="filterBy('invited')"/>
        <span class="ml-2">Invited</span>
      </label>
      <label for="not--suitable" class="text-white leading-relaxed text-md my-2">
        <input id="not" type="radio" name="candidates" @click="filterBy('good')"/>
        <span class="ml-2">Good Fit</span>
      </label>
      <label for="recommended" class="text-white leading-relaxed text-md my-2">
        <input id="rec" type="radio" name="candidates" @click="filterBy('bad')"/>
        <span class="ml-2">Bad Fit</span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "ViewCandidates",
  props: {
    current: String | Number
  },
  data() {
    return {
      interviews: null,
      selected: this.current,

    };
  },
  methods: {
    getAllInterviews() {
      return axios.get("/interview/get-interviews").then(response => {
        this.interviews = response.data;
      });
    },
    getCurrentSelectedJob() {},
    filterBy(filter) {
      let value = filter;
      this.$emit('filtered', value);
      // this.$inertia.post(this.$route('filter.candidates', [this.selected, filter]), {replace:false, only: ['candidate']});
    }
  },
  mounted() {
    this.getAllInterviews();
  },
  computed: {},
  watch: {
    selected() {
      this.$inertia.visit(this.$route("show.candidates", this.selected), {
        replace: true,
        preserveScroll: true
      });
    }
  }
};
</script>

<style scoped>
</style>
