<template>
  <layout title="Complete Setup" class="h-screen">
    <div class="flex justify-center w-full mb-12">
      <div class="bg-white px-16 mt-10 w-1/2">
        <div class="flex justify-center font-semibold leading-tight text-md mt-5 mb-6">
          <p class="text-black">Create Password</p>
        </div>
        <div class="flex flex-col mb-6">
          <input
            class="ii--form-input-full text-black mb-4 focus:outline-none"
            id="password"
            type="password"
            placeholder="Password"
            required
            aria-required="true"
            v-model="form.password"
            autocomplete="false"
          />
          <p class="text-red-500 text-xs italic text-right">Minimum of 8 characters.</p>
        </div>
        <div class="mb-6">
          <input
            class="bg-cherryred-primary py-4 px-8 w-full"
            value="Create Password"
            type="button"
            @click="updatePassword()"
          />
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout";

export default {
  name: "Password",
  components: {
    Layout
  },
  props: {},
  data() {
    return {
      form: {
        forename: this.$page.auth.user.first_name,
        surname: this.$page.auth.user.last_name,
        password: "",
        user_id: this.$page.auth.user.id,
        email: this.$page.auth.user.email
      }
    };
  },
  methods: {
    updatePassword() {
      this.sending = true;
      let data = new FormData();
      data.append("user_id", this.form.user_id);
      data.append("forename", this.form.forename);
      data.append("surname", this.form.surname);
      data.append("email", this.form.email);
      data.append("password", this.form.password);
      this.$inertia.post("/update-account", data).then(() => {
        this.sending = false;
      });
    }
  }
};
</script>
