<template>
  <div class="pt-8 px-8 w-full">
    <p class="font-medium text-xl mb-4">View Interviews</p>
    <div class="my-4">
      <label
        for="interview--select"
        class="block tracking-wide text-white text-sm font-bold mb-4"
      >Select an Interview</label>
      <div class="relative">
        <select
          name="interview--select"
          class="block appearance-none w-full bg-white text-black py-2 px-4 pr-8 leading-tight"
          v-model="selected"
        >
          <option
            v-for="(interview, index) in interviews"
            :key="index"
            :value="interview.id"
            class="text-black"
          >{{ interview.title }}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuickInterview",
  props: {
    interviews: Array
  },
  data() {
    return {
      selected: ""
    };
  },
  methods: {},
  computed: {},
  watch: {
    selected() {
      this.$inertia.visit(this.$route("show.candidates", this.selected), {
        replace: true,
        preserveScroll: true
      });
    }
  }
};
</script>
