<template>
  <transition type="modal-fade">
    <div class="modal w-full lg:w-1/2 mt-10 mb-10 mx-auto top-0 left-0 shadow-2xl">
      <div class="modal-header">
        <div class="flex justify-around">
          <p class="text-black ii--subheading-text my-8">Add Team Member</p>
          <button type="button" class="text-black float-right" @click="close">
            <i class="fa fa-2x fa-times"></i>
          </button>
        </div>
      </div>
      <div class="modal-body flex flex-col justify-center">
        <form method="post" id="invite--candidate" class="max-w-4xl mb-8 px-4 lg:px-20">
          <input
            type="text"
            id="candidate--forename"
            class="ii--form-input-full text-black"
            v-model="team.forename"
            placeholder="Forename"
          />
          <input
            type="text"
            id="candidate--surname"
            class="ii--form-input-full text-black"
            v-model="team.surname"
            placeholder="Surname"
          />
          <input
            type="email"
            id="candidate--email"
            class="ii--form-input-full text-black"
            v-model="team.email"
            placeholder="Email"
          />
          <button
            type="button"
            class="ii--button-red w-full my-8"
            @click="addTeamMember()"
          >Add Team Member</button>
        </form>
      </div>
      <div class="modal-footer"></div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "AddTeamMember",
  props: {
    errors: Object,
    interview: Object
  },
  data() {
    return {
      team: {
        forename: "",
        surname: "",
        email: ""
      }
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    addTeamMember() {
      let companyId = this.$page.auth.user.company.id;
      let data = new FormData();
      data.append("forename", this.team.forename);
      data.append("surname", this.team.surname);
      data.append("email", this.team.email);
      data.append("company_id", this.$page.auth.user.company.id);

      this.$inertia.post(this.$route("company.add.member"), data).then(resp => {
        this.$emit("close");
        this.team = "";
      });
    }
  },
  computed: {},
  watch: {}
};
</script>
