<template>
  <layout title="Candidate Profile">
    <div class="flex flex-auto">
      <!-- Left Col -->
      <div
        class="hidden md:flex flex-shrink-0 bg-navy-primary w-full md:w-4/12 lg:w-2/12 mr-3 h-auto"
      >
        <div class="w-full py-8 px-8">
          <inertia-link
            :href="$route('show.candidates', response.interview_id)"
            class="bg-yellow text-black flex items-center justify-between text-md font-semibold w-full py-2 rounded-lg"
          >
            <i class="fa fa-chevron-left ml-4 text-sm"></i>
            <span class="px-2">Back to results</span>
          </inertia-link>
          <div class="flex items-center my-10">
            <p class="text-white font-bold text-lg">Candidate Details</p>
          </div>
          <div class="flex items-center my-10">
            <div class="w-1/3 mr-4">
              <p class="text-md text-white leading-relaxed">{{ this.candidate.forename}}</p>
              <p class="text-lg text-white leading-relaxed font-bold">{{ this.candidate.surname }}</p>
            </div>
            <div class="w-2/3 mr-4">
              <img
                class="border-4 border-white rounded-full w-16 h-16 float-right"
                :src="this.candidate.photo"
              />
            </div>
          </div>
          <hr class="border-gray-100 opacity-25 my-5" />
          <div class="flex flex-col justify-start">
            <div class="py-4">
              <p class="text-yellow text-sm leading-tight font-bold">Email</p>
              <p class="text-white text-xs leading-snug mt-2">{{ this.candidate.email }}</p>
            </div>
            <div class="py-4">
              <p class="text-yellow text-sm leading-tight font-bold">Phone Number:</p>
              <p class="text-white text-xs leading-snug mt-2">{{ this.candidate.phoneNumber }}</p>
            </div>
          </div>
          <hr class="border-gray-100 opacity-25 my-5" />
          <div class="flex flex-col">
            <label
              for="question--select"
              class="text-white font-medium mb-2"
            >Select a question to view</label>
            <select
              name="question--select"
              class="block form-select appearance-none bg-white text-black py-2 px-4 pr-8 placeholder-black"
              style="border-radius: 0 !important"
              @change="selectQuestion(selected)"
              v-model="selected"
            >
              <option
                class="text-black"
                v-for="(answer, index) in response.candidate_answers"
                :key="index"
                :value="index"
              >{{ answer.question }}</option>
            </select>
          </div>
        </div>
      </div>
      <!-- Right Col -->
      <div class="lg:flex lg:flex-grow bg-background hidden" v-if="this.question">
        <div class="mx-8 my-8 w-full">
          <div class="hidden lg:flex flex-col bg-white h-3/4 w-full mb-2 p-8">
            <div class="flex justify-between mb-6">
              <div class="flex justify-start w-full">
                <button
                  class="ii--button-darknavy rounded-lg px-2 w-3/12 mr-2 text-sm"
                  v-if="this.question.questionNumber > 1"
                  @click="selectQuestion(question.questionNumber -2)"
                >
                  <i class="fa fa-arrow-left"></i>
                  Previous
                </button>
              </div>
              <div class="flex justify-center w-full">
                <button
                  v-if="this.question.format !== 'text'"
                  class="ii--button-red rounded-lg w-full mr-4"
                  @click="analyseVideo(question.questionNumber)"
                >Analyse this interview</button>
              </div>
              <div class="flex justify-end w-full">
                <button
                  class="ii--button-darknavy rounded-lg px-2 w-3/12 text-sm"
                  @click="selectQuestion(question.questionNumber)"
                  :class="question.questionNumber >= this.questionAnswers.length ? 'hidden' : 'block'"
                >
                  Next
                  <i class="fa fa-arrow-right"></i>
                </button>
              </div>
            </div>
            <div class="flex items-center content-center w-full h-16 mb-4">
              <div class="flex flex-col justify-start w-2/3 mx-2">
                <p
                  class="text-2xl text-black"
                >Q{{this.question.questionNumber }}. {{ this.question.question }}</p>
              </div>
              <div class="w-1/3 mx-2 flex justify-between"></div>
            </div>
            <div class="flex w-full h-85">
              <div class="w-2/3 mx-2">
                <div class="h-full" v-if="this.question.format == 'video'">
                  <video-player :videoFile="this.question.answer" />
                </div>
                <div v-else class="h-full overflow-y-auto">
                  <vue-editor
                    id="candidateAnswer"
                    name="candidateAnswer"
                    class="text-black bg-white"
                    v-model="this.question.answer"
                    :editorToolbar="editorToolbar"
                    :disabled="true"
                    :editorOptions="editorOptions"
                  ></vue-editor>
                </div>
              </div>
              <div class="w-1/3 bg-navy-primary mx-2 h-full overflow-y-scroll">
                <div
                  class="flex flex-col items-center m-8"
                  v-for="(question, index) in response.candidate_answers"
                  :key="index"
                >
                  <!-- Playlist start -->
                  <div
                    class="flex items-center content-center w-full"
                    @click="selectQuestion(index)"
                  >
                    <div class="w-3/12 mr-4">
                      <div v-if="question.format == 'video'">
                        <img :src="question.thumbnail" alt="video capture" />
                      </div>
                      <div v-else class="flex justify-center">
                        <i class="far fa-2x fa-file-alt text-white" alt="text answer" />
                      </div>
                    </div>
                    <div class="w-9/12">
                      <p class="font-bold">Question {{ question.questionNumber }}</p>
                      <p>{{ question.question }}</p>
                    </div>
                  </div>
                  <!-- playlist end -->
                  <hr class="w-full border border-white my-5" />
                </div>
              </div>
            </div>
          </div>
          <div class="hidden lg:flex bg-white h-auto w-full">
            <div class="py-8 px-8 w-full">
              <div v-if="this.overall == null">
                <div class="flex justify-center w-full mx-auto mb-6 mt-4">
                  <p
                    class="text-center text-black ii--subheading-text"
                  >How do you feel about this candidate?</p>
                </div>
                <div
                  class="flex w-1/2 justify-center items-center content-center mx-auto mt-2 mb-4"
                >
                  <div class="w-1/2 h-16 mx-4">
                    <button
                      class="bg-green w-full px-4 py-4 rounded-lg"
                      @click="goodFit()"
                    >Fit for the role</button>
                  </div>
                  <div class="w-1/2 h-16 mx-4">
                    <button
                      class="bg-iired w-full px-4 py-4 rounded-lg"
                      @click="rejectFit()"
                    >Not a good Fit</button>
                  </div>
                </div>
              </div>
              <div v-else class="flex justify-center content-center">
                <p class="text-black text-2xl mb-4">
                  You have previously selected
                  <span class="font-bold" v-if="this.overall">Good</span>
                  <span class="font-bold" v-else>Not a Good</span> fit
                </p>
              </div>
              <div class="flex flex-wrap-reverse xl:flex-no-wrap">
                <div class="flex h-auto w-full xl:w-4/6 mt-4 mx-4">
                  <div class="w-full bg-darknavy-lighter h-auto py-8 px-8">
                    <div class="flex justify-between content-center items-center mt-4 mb-6">
                      <p class="font-bold text-lg">Team Comments on this candidate</p>
                      <p class="text-right text-sm">( {{ comments.length }} )</p>
                    </div>
                    <div
                      class="flex flex-col h-2/4 overflow-y-scroll"
                      :class="comments.length > 4 ? 'overflow-y-scroll' : ''"
                    >
                      <team-comment
                        :comments="comments"
                        :candidate="this.response.candidate_id"
                        :interview="this.response.interview_id"
                        :company="this.response.candidate.company_id"
                      ></team-comment>
                    </div>
                    <div class="flex content-center items-center mt-8 text-black">
                      <textarea
                        class="appearance-none bg-white md:w-9/12 lg:w-10/12 px-4 py-4"
                        placeholder="Leave your comment here"
                        rows="6"
                        id="candidate--comment"
                        v-model="userComment"
                      ></textarea>
                      <button
                        type="button"
                        class="bg-green h-46 w-40 text-white"
                        @click="leaveComment()"
                      >
                        <p class="font-bold text-xl mb-4 break-words px-2">Submit Comments</p>
                        <i class="fa fa-2x fa-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  class="flex flex-wrap h-auto w-full xl:w-2/6 mt-4 mx-4 overflow-y-scroll bg-darknavy-lighter"
                >
                  <div
                    class="w-full h-64 bg-darknavy-lighter py-8 px-8"
                    v-if="this.response.skills != null"
                  >
                    <skill-rating
                      :skills="this.response.skills.skills"
                      :candidate="this.response.candidate_id"
                      :interview="this.response.interview_id"
                      :company="this.response.candidate.company_id"
                    />
                  </div>
                  <div v-else class="w-full bg-darknavy-lighter h-full py-12 px-8">
                    <p class="font-bold text-lg">Skills</p>
                    <p>No Skills Added to Interview</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="hidden lg:flex flex-col bg-white h-screen w-full mb-2 p-8">
        <div class="flex justify-center content-center items-center h-full mx-auto">
          <p class="text-black text-xl">No Candidate responses found</p>
        </div>
      </div>

      <div
        class="fixed inset-0 w-full h-screen flex items-center justify-center bg-modal"
        v-show="loadModal"
      >
        <div
          class="relative bg-navy-primary w-full max-w-xl m-auto h-auto flex flex-col justify-center items-center content-center morphing-demo shadow-2xl rounded-lg"
        >
          <p class="text-black mt-20 mb-10 text-4xl font-bold text-white">Analyzing Video</p>
          <svg
            width="280"
            height="280"
            viewBox="0 0 140 140"
            class="mb-20"
            style="stroke: #fff; fill: #b50227;"
          >
            <g fill="none" fill-rule="evenodd">
              <g fill-opacity="0.65" transform="translate(0 6)">
                <polygon
                  points="70 0 136.574 48.369 111.145 126.631 28.855 126.631 3.426 48.369"
                  style="fill: #b50227;"
                />
                <polygon
                  points="70 18 119.455 53.931 100.565 112.069 39.435 112.069 20.545 53.931"
                  style="fill: #b50227;"
                />
                <polygon
                  points="70 34.86 101.727 57.911 89.609 95.209 50.391 95.209 38.273 57.911"
                  style="fill: #b50227;"
                />
                <polygon
                  points="70 50.898 84.864 61.697 79.186 79.171 60.814 79.171 55.136 61.697"
                  style="fill: #b50227;"
                />
              </g>
              <polygon
                class="polymorph"
                stroke-width="4"
                style="stroke: #091b2b"
                points="70 24 119.574 60.369 100.145 117.631 50.855 101.631 3.426 54.369"
              />
            </g>
          </svg>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout";
import { VueEditor } from "vue2-editor";
import TeamComment from "./TeamComment.vue";
import ProfileQuestion from "./ProfileQuestion.vue";
import VideoPlayer from "./VideoPlayer.vue";
import SkillRating from "./SkillsRating.vue";

export default {
  name: "CandidateProfile",
  components: {
    Layout,
    TeamComment,
    ProfileQuestion,
    VideoPlayer,
    SkillRating,
    VueEditor
  },
  props: {
    response: Object
  },
  created() {
    this.getComments(this.response.candidate_id);
  },
  mounted() {
    this.candidateAnswers();
    this.setQuestion();
    this.getOverallRating();
  },
  data() {
    return {
      selected: 0,
      overall: null,
      candidate: {
        forename: this.response.candidate.forename,
        surname: this.response.candidate.surname,
        photo: this.response.candidate.photo,
        email: this.response.candidate.email,
        phoneNumber: this.response.candidate.phone_number
      },
      question: [],
      questionAnswers: [],
      userComment: null,
      comments: [],
      editorToolbar: [[], []],
      editorOptions: {
        readOnly: true
      },
      skills: [],
      loadModal: false
    };
  },
  methods: {
    goodFit() {
      this.overall = true;
      this.candidateOverAllRating();
    },
    rejectFit() {
      this.overall = false;
      this.candidateOverAllRating();
    },
    candidateOverAllRating() {
      let data = new FormData();
      data.append("user", this.$page.auth.user.id);
      data.append("candidate", this.response.candidate_id);
      data.append("interview", this.response.interview_id);
      data.append("company", this.response.interview.company_id);
      data.append("overall", this.overall);

      axios
        .post(
          this.$route("candidate.overall.rating", this.response.candidate_id),
          data
        )
        .then(response => {
          let fit = response.data.fit == 1 ? "Good" : "Bad";
          if (fit == "Bad") {
            alertify.warning("You have selected " + fit + " fit");
          } else {
            alertify.success("You have selected " + fit + " fit");
          }
        });
    },
    candidateAnswers() {
      this.questionAnswers = this.response.candidate_answers;
    },
    selectQuestion(question) {
      let i = parseInt(question);
      const answer = this.response.candidate_answers[i];
      this.question = answer;
      if (this.question.format == "video") {
        this.question.answer = answer.answer;
        this.question.thumb = answer.thumbnail;
      }
    },
    setQuestion() {
      const answer = this.response.candidate_answers[0];
      //   const answer = JSON.parse(this.response.candidate_answers)[0];
      if (answer) {
        this.question = answer;
        if (this.question.format == "video") {
          this.question.answer = answer.answer;
          this.question.thumb = answer.thumbnail;
        }
      }
    },
    analyseVideo(questionNumber) {
      this.loadModal = true;
      let question = questionNumber;
      let data = new FormData();
      data.append("question", question);
      data.append("user", this.$page.auth.user.id);
      data.append("candidate", this.response.candidate_id);
      data.append("interview", this.response.interview_id);
      data.append("company", this.response.candidate.company_id);

      setTimeout(() => {
        axios.post(this.$route("analyze.video"), data).then(response => {
          if (response.data.success) {
            let result = new FormData();
            result.append("candidate", response.data.candidate);
            result.append("question", response.data.question);
            this.$inertia.replace(this.$route("video.analysis"), {
              method: "post",
              data: {
                candidate: response.data.candidate,
                question: response.data.question
              },
              preserveState: true,
              preserveScroll: true
            });
          }
        });
      }, 3000);
    },
    leaveComment() {
      if (this.userComment != null) {
        let data = new FormData();
        data.append("user", this.$page.auth.user.id);
        data.append("candidate", this.response.candidate_id);
        data.append("interview", this.response.interview_id);
        data.append("company", this.response.candidate.company_id);
        data.append("comment", this.userComment);

        axios.post("/candidates/add-comment", data).then(response => {
          if (response.data.success) {
            alertify.success(response.data.message);
            this.comments.push({
              id: response.data.comment.id,
              commentorName: this.$page.auth.user.name,
              comment: this.userComment,
              date: moment()
            });
            this.userComment = null;
          } else {
            alertify.error(response.data.message);
          }
        });
      } else {
        alertify.error("Please leave a comment");
      }
    },
    getComments(candidateId) {
      let id = candidateId;
      axios.get("/candidates/get-comments/" + id).then(response => {
        if (response.data.success) {
          _.forEach(response.data.comments, (value, key) => {
            this.comments.push({
              id: value.id,
              commentorName: value.user.name,
              comment: value.comment,
              date: value.created_at
            });
          });
        }
      });
    },
    getOverallRating() {
      let data = new FormData();
      data.append("user", this.$page.auth.user.id);
      data.append("candidate", this.response.candidate_id);
      axios
        .post(
          this.$route(
            "get.candidate.overall.rating",
            this.response.candidate_id
          ),
          data
        )
        .then(response => {
          if (response.data.candidate_rating != null) {
            this.overall = response.data.candidate_rating == 1 ? true : false;
          }
        });
    }
  },
  watch: {
    loadModal: {
      handler() {
        if (this.loadModal) {
          this.$anime({
            targets: ".morphing-demo .polymorph",
            points: [
              {
                value: [
                  "70 24 119.574 60.369 100.145 117.631 50.855 101.631 3.426 54.369",
                  "70 41 118.574 59.369 111.145 132.631 60.855 84.631 20.426 60.369"
                ]
              },
              {
                value:
                  "70 6 119.574 60.369 100.145 117.631 39.855 117.631 55.426 68.369"
              },
              {
                value:
                  "70 57 136.574 54.369 89.145 100.631 28.855 132.631 38.426 64.369"
              },
              {
                value:
                  "70 24 119.574 60.369 100.145 117.631 50.855 101.631 3.426 54.369"
              }
            ],
            easing: "easeOutQuad",
            duration: 2000,
            loop: true
          });
        }
      }
    }
  }
};
</script>
