<template>
  <div>
    <!-- Timer -->
    <div class="flex flex-col justify-end w-1/2 h-10 -mt-32 md:-mt-26 mr-auto">
      <p class="text-xl text-center">Preparation Remaining 1:</p>
      <div v-if="this.prepTimer == 120">
        <p class="text-center mt-1 mb-3 text-4xl">2:00</p>
      </div>
      <div v-else>
        <p
          class="text-center mt-1 mb-3"
          :class="this.prepTimer < '00:30' ? 'text-cherryred-primary text-4xl' : 'text-4xl'"
        >{{ this.prepTimer }}</p>
      </div>

      <!-- Question Timer -->
      <!-- <div class="flex flex-col justify-end ml-auto w-1/2 h-20 mt-12 md:mb-10 xl:mt-4 md:mt-12">
        <p class="text-xl text-center">Question Remaining:</p>
        <div v-if="this.questionTimer == 180">
          <p class="text-center mt-1 mb-3 text-4xl">3:00</p>
        </div>
        <div v-else>
          <p
            class="text-center mt-1 mb-3"
            :class="this.questionTimer < '00:30' ? 'text-cherryred-primary text-4xl' : 'text-4xl'"
          >{{ this.questionTimer }}</p>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    prep: null
  },
  mounted() {},
  data: {
    prepTimer: 120,
    questionTimer: 180
  },
  methods: {
    startPrepTimer() {
      this.startRecording = false;
      let duration = moment.duration(this.prepTimer * 1000, "milliseconds");
      let interval = 1000;
      if (this.attempts >= 0) {
        this.timer = setInterval(() => {
          duration = moment.duration(duration - interval, "milliseconds");
          this.prepTimer =
            Math.floor(duration.asHours()) +
            moment.utc(duration.asMilliseconds()).format("m:ss");
          if (duration.minutes() == 0 && duration.seconds() == 0) {
            clearInterval(this.timer);
            // this.startRecording = true;
            this.$emit("startRecording");
          }
        }, interval);
      }
    }
  },
  watch: {
    prep: {
      handler() {
        console.log("1");
      }
    }
  }
};
</script>
