<template>
  <layout title="Candidate Phone Number" class="h-screen">
    <div class="flex flex-col items-center justify-center content-center">
      <form class="bg-white py-8 px-8 mt-10 text-black">
        <input
          class="ii--form-input-full mb-6"
          type="text"
          id="phone--number"
          name="phone-number"
          placeholder="Phone Number"
          v-model="candidate.phone_number"
        />

        <button
          type="button"
          class="ii--button-red w-full"
          @click="addPhoneNumber()"
        >Add Phone Number</button>
      </form>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout";

export default {
  name: "CandidatePhoneNumber",
  components: {
    Layout
  },
  props: {
    candidate: Object,
    interview: Object | Array
  },
  data() {
    return {
      addPhoneNumber() {
        console.table(this.candidate);
        this.$inertia.post(
          this.$route("candidate.phone", this.candidate.id),
          this.candidate.phone_number
        );
      }
    };
  },
  methods: {},
  computed: {},
  watch: {}
};
</script>
