<template>
  <main class="bg-ii antialiased font-body text-white">
    <div class="w-full" v-if="signedIn">
      <navigation></navigation>
    </div>
    <div v-else>
      <guest-navigation :image="companyImage" />
    </div>
    <div>
      <slot />
    </div>
  </main>
</template>

<script>
import Navigation from "../partials/Navigation.vue";
import GuestNavigation from "../partials/GuestNavigation.vue";
import FlashMessages from "./FlashMessage.vue";

export default {
  name: "",
  components: {
    Navigation,
    GuestNavigation,
    FlashMessages
  },
  mounted() {
    if (this.$page.auth.user != null) {
      if (this.$page.auth.user.verified) {
        this.signedIn = true;
      }
    }
    // if (this.$page.interview != null) {
    //   if (this.$page.interview.company.company_logo != null) {
    //     this.companyImage = this.$page.interview.company.company_logo;
    //   }
    // }
  },
  props: {},
  data() {
    return {
      signedIn: false,
      companyImage: "/img/interview_intelligence_logo.png"
    };
  },
  methods: {},
  watch: {
    title: {
      immediate: true,
      handler(title) {
        document.title = title
          ? `${title} | Interview Intelligence`
          : "Interview Intelligence";
      }
    }
  }
};
</script>
