<template>
  <layout title="interview show">
    <div class="flex overflow-hidden min-h-screen">
      <!-- Left Col -->
      <div class="hidden md:flex bg-navy-primary w-full md:w-4/12 lg:w-2/12 mr-3 h-auto">
        <sidebar :interview="interview" />
      </div>
      <!-- Right Col -->
      <div class="flex flex-grow bg-background h-screen md:h-auto justify-center text-black">
        <div class="flex flex-wrap w-full md:hidden">
          <!-- hidden on small quick links to interviews only on large> -->
        </div>
        <div v-if="candidates == 0">
          <div class="flex flex-col">
            <p
              class="text-center md:my-10 text-black ii--header-text"
            >{{ interview.title | truncateText }}</p>
            <p class="text-center">You have no candidates yet</p>
            <div
              class="flex flex-col mx-4 lg:flex-row justify-center items-center content-center mb-8 md:my-8"
            >
              <div class="w-full lg:w-1/2 h-1/4 mx-2 mb-2">
                <button
                  type="button"
                  class="bg-white h-full w-full lg:px-8 rounded-lg"
                  @click="addCandidate()"
                >
                  <p class="text-center mb-2">Invite Candidates Manually</p>
                  <p
                    class="text-center text-sm"
                  >Use an email adress to send an invitation to the candidate</p>
                  <i class="fa fa-5x fa-user-plus text-green mt-4"></i>
                </button>
              </div>
              <div class="w-full lg:w-1/2 h-1/4 mx-2 mb-2">
                <button
                  type="button"
                  class="bg-white w-full h-full lg:px-8 rounded-lg"
                  @click="addMultipleCandidates()"
                >
                  <p class="text-center mb-2">Invite Candidates by uploading CSV</p>
                  <p
                    class="text-center text-sm"
                  >Use our CSV template and upload to add multiple candidates</p>
                  <i class="fa fa-5x fa-upload text-green mt-4"></i>
                </button>
              </div>
            </div>
            <div class="flex flex-row justify-center md:mt-10 lg:mt-0 lg:justify-end mx-6">
              <a class="ii--button-red px-10" href="/interview/candidates/csv">CSV Template</a>
            </div>
          </div>
        </div>

        <div v-else class="flex flex-col w-full mx-10">
          <p class="text-center my-10 text-black ii--header-text">{{ interview.title }}</p>
          <div class="flex flex-row justify-end mb-4">
            <button
              class="ii--button-red mx-4 px-6 rounded-lg"
              @click="addCandidate()"
            >Invite Candidate</button>
            <button
              class="ii--button-red px-6 rounded-lg"
              @click="addMultipleCandidates()"
            >Upload Candidate</button>
          </div>
          <table class="table table-striped -mx-10 lg:mx-0">
            <thead class="bg-white text-black">
              <th class="w-1/5">Candidate</th>
              <th class="w-1/5">Created</th>
              <th class="w-1/5">Status</th>
              <th class="w-2/5">Action</th>
            </thead>
            <tbody>
              <tr v-for="(candidate, index) in interview.candidates" :key="index">
                <td class="capitalize">{{ candidate.name }}</td>
                <td>{{ candidate.created_at | formatDate}}</td>
                <td class="capitalize">
                  <span v-if="candidate.clicked == 1">Visited</span>
                  <span v-else-if="candidate.clicked == 0">Not Opened</span>
                  <span v-else-if="candidate.complete == 1">Completed</span>
                </td>
                <td class="flex flex-col lg:flex-row">
                  <inertia-link
                    :href="$route('delete.candidate', candidate.id)"
                    class="bg-darknavy-primary text-white py-2 px-6 font-bold rounded-lg mx-2"
                  >Delete</inertia-link>
                  <button
                    class="bg-cherryred-primary text-white py-2 px-4 font-bold rounded-lg mx-2"
                    @click="reinvite(candidate.id)"
                  >Reinvite</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        class="w-screen h-screen absolute flex items-center justify-center content-center bg-modal"
        v-show="inviteSingular"
      >
        <invite-candidate
          :interview="interview"
          @close="inviteSingular = !inviteSingular"
          class="bg-white shadow-lg p-8"
        />
      </div>

      <div
        class="w-screen h-screen absolute flex items-center justify-center content-center bg-modal"
        v-show="inviteMultiple"
      >
        <invite-multiple
          :interview="interview"
          class="bg-white shadow-lg p-8"
          @close="inviteMultiple = !inviteMultiple"
        />
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout";
import InviteCandidate from "../../components/InviteCandidates.vue";
import inviteMultiple from "../../components/InviteMultiple.vue";
import Sidebar from "../../components/Sidebar.vue";

export default {
  name: "InterviewShow",
  components: {
    Layout,
    InviteCandidate,
    inviteMultiple,
    Sidebar
  },
  props: {
    interview: Object
  },
  data() {
    return {
      inviteSingular: false,
      inviteMultiple: false,
      candidates: 0
    };
  },
  methods: {
    addCandidate() {
      this.inviteSingular = !this.inviteSingular;
      this.inviteMultiple = false;
    },
    addMultipleCandidates() {
      this.inviteMultiple = !this.inviteMultiple;
      this.inviteSingular = false;
    },
    getNumberOfCandidates() {
      return this.interview.candidates.length;
    },
    reinvite(id) {
      axios.post(this.$route("reinvite.candidate", id)).then(response => {
        if (response.status == 200) {
          alertify.notify(response.data.message, "success");
        }
        alertify.notify("Sorry, there was an error", "error");
      });
    }
  },
  watch: {},
  mounted() {
    this.candidates = this.getNumberOfCandidates();
  },
  computed: {},
  watch: {}
};
</script>
