<template>
  <transition type="modal-fade">
    <div
      class="modal w-full h-full lg:w-1/2 mt-10 mb-10 mx-auto top-0 left-0 bg-white shadow-lg p-8 overflow-y-scroll"
    >
      <div class="modal-header">
        <div class="flex justify-around">
          <p
            class="text-black text-center mx-auto text-xl lg:ii--header-text my-4"
          >Here are some tips</p>
          <button type="button" class="text-black float-right" @click="close">
            <i class="fa fa-2x fa-times"></i>
          </button>
        </div>
      </div>
      <div class="modal-body flex flex-col justify-center w-3/4 ml-auto mr-auto">
        <div class="flex flex-wrap -mx-3 overflow-hidden">
          <div class="my-3 px-3 w-full lg:w-1/2 overflow-hidden">
            <!-- Column Content -->
            <div
              class="flex flex-col justify-center rounded overflow-hidden h-1/4 lg:h-2/4 lg:-mt-16"
            >
              <div
                class="flex justify-center items-center bg-darknavy-primary mx-auto rounded-full z-50 w-16 h-16 p-16"
              >
                <i class="far fa-3x fa-lightbulb text-cherryred-primary"></i>
              </div>
              <div class="w-full overflow-hidden h-1/4 -mt-16">
                <div class="bg-darknavy-primary w-full py-10 px-2">
                  <p
                    class="w-full text-white text-base px-4 text-center break-words mt-4 mb-0"
                  >This is text explaining what this section does and how it works</p>
                </div>
              </div>
            </div>
          </div>
          <div class="my-3 px-3 w-full lg:w-1/2 overflow-hidden">
            <!-- Column Content -->
            <div
              class="flex flex-col justify-center rounded overflow-hidden h-1/4 lg:h-2/4 lg:-mt-16"
            >
              <div
                class="flex justify-center items-center bg-darknavy-primary mx-auto rounded-full z-50 w-16 h-16 p-16"
              >
                <i class="fas fa-3x fa-microphone text-cherryred-primary"></i>
              </div>
              <div class="w-full overflow-hidden h-1/4 -mt-16">
                <div class="bg-darknavy-primary w-full py-10 px-2">
                  <p
                    class="w-full text-white text-base px-4 text-center break-words mt-4 mb-0"
                  >This is text explaining what this section does and how it works</p>
                </div>
              </div>
            </div>
          </div>
          <div class="my-3 px-3 w-full lg:w-1/2 overflow-hidden">
            <!-- Column Content -->
            <div
              class="flex flex-col justify-center rounded overflow-hidden h-1/4 lg:h-2/4 lg:-mt-16"
            >
              <div
                class="flex justify-center items-center bg-darknavy-primary mx-auto rounded-full z-50 w-16 h-16 p-16"
              >
                <i class="fas fa-3x fa-camera text-cherryred-primary"></i>
              </div>
              <div class="w-full overflow-hidden h-1/4 -mt-16">
                <div class="bg-darknavy-primary w-full py-10 px-2">
                  <p
                    class="w-full text-white text-base px-4 text-center break-words mt-4 mb-0"
                  >This is text explaining what this section does and how it works</p>
                </div>
              </div>
            </div>
          </div>
          <div class="my-3 px-3 w-full lg:w-1/2 overflow-hidden">
            <!-- Column Content -->
            <div
              class="flex flex-col justify-center rounded overflow-hidden h-1/4 lg:h-2/4 lg:-mt-16"
            >
              <div
                class="flex justify-center items-center bg-darknavy-primary mx-auto rounded-full z-50 w-16 h-16 p-16"
              >
                <i class="far fa-3x fa-user text-cherryred-primary"></i>
              </div>
              <div class="w-full overflow-hidden h-1/4 -mt-16">
                <div class="bg-darknavy-primary w-full py-10 px-2">
                  <p
                    class="w-full text-white text-base px-4 text-center break-words mt-4 mb-0"
                  >This is text explaining what this section does and how it works</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "CandidateTips",
  props: {},
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit("close");
    }
  },
  computed: {},
  watch: {}
};
</script>
