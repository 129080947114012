<template>
  <nav class="lg:flex lg:justify-between lg:content-center lg:items-center bg-white pb-4">
    <div class="flex justify-between content-center items-center flex-wrap bg-white px-4 pt-6">
      <div class>
        <span class="bg-white h-8 lg:h-24">
          <a href="/">
            <img src="/img/interview_intelligence_logo.png" />
          </a>
        </span>
      </div>
      <div class="lg:hidden">
        <button type="button" @click="isOpen = !isOpen" class="block">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z" />
          </svg>
        </button>
      </div>
    </div>
    <div
      :class="isOpen ? 'block' : 'hidden' "
      class="lg:flex text-darknavy-primary bg-white px-4 pt-6 lg:items-center lg:text-lg"
    >
      <div class="block lg:flex justify-between items-center content-center mr-4">
        <inertia-link
          class="block lg:mr-4 bg-iiblue-darker text-white px-5 py-2 rounded-lg font-bold hover:opacity-75"
          title="Dashboard"
          :href="$route('dashboard')"
        >Dashboard</inertia-link>
        <!-- <inertia-link
          class="block lg:mr-4 hover:text-cherryred-primary focus:text-cherryred-primary"
          :href="$route('interview')"
        >Interviews</inertia-link>-->
        <!-- <inertia-link
          class="block mt-1 lg:mr-4 hover:text-cherryred-primary focus:text-cherryred-primary lg:mt-0"
          :href="$route('stats')"
        >Stats</inertia-link>-->
        <inertia-link
          class="block mt-1 lg:mr-4 hover:text-cherryred-primary focus:text-cherryred-primary lg:mt-0"
          title="Account"
          :href="$route('account')"
        >Account</inertia-link>
        <div v-if="isVisible">
          <inertia-link
            class="block mt-1 lg:mr-4 hover:text-cherryred-primary focus:text-cherryred-primary lg:mt-0"
            title="Admin"
            :href="$route('admin')"
          >Admin</inertia-link>
        </div>
        <inertia-link
          class="block mt-1 lg:mr-4 hover:text-cherryred-primary focus:text-cherryred-primary lg:mt-0"
          title="Logout"
          :href="$route('logout')"
          method="post"
        >Logout</inertia-link>

        <!-- <inertia-link
          class="block mt-1 lg:mr-4 hover:text-cherryred-primary focus:text-cherryred-primary lg:mt-0"
          :href="$route('support')"
        >Support</inertia-link>-->
      </div>
      <div>
        <!-- <profile-navigation /> -->
      </div>
    </div>
  </nav>
</template>

<script>
import ProfileNavigation from "./ProfileNavigation.vue";
export default {
  name: "Navigation",
  components: {
    ProfileNavigation
  },
  props: {},
  created() {
    if (this.$page.auth.user.role == "superadministrator") {
      this.isVisible = !this.isVisible;
    }
  },
  data() {
    return {
      isOpen: false,
      verified: false,
      isVisible: false
    };
  },
  methods: {},
  computed: {},
  watch: {}
};
</script>
