<template>
  <transition type="modal-fade">
    <div class="modal w-1/2 mt-10 mb-10 mx-auto top-0 left-0">
      <div class="modal-header">
        <button type="button" class="text-black float-right" @click="close">
          <i class="fa fa-2x fa-times"></i>
        </button>
        <div class="flex flex-col justify-center items-center content-center">
          <p class="text-black ii--subheading-text my-8">Upload CSV</p>
          <p class="text-black text-md my-4">Submit your form below and invite candidates</p>
        </div>
      </div>
      <div class="modal-body flex flex-col justify-center">
        <form method="post" id="invite--candidate" class="max-w-6xl mb-8 px-20">
          <!-- dropzone -->
          <file-upload
            :url="this.file.url"
            :maxFiles="this.file.max"
            :maxFileSize="this.file.fileSize"
            :fileTypes="this.file.type"
            :interview="this.interview"
            :user="this.$page.auth.user"
            @uploaded="close"
          />
        </form>
      </div>

      <div class="flex justify-end md:mt-10 lg:mt-0 lg:justify-end mx-6">
        <a class="ii--button-red px-10 rounded-lg" href="/interview/candidates/csv">CSV Template</a>
      </div>
    </div>
  </transition>
</template>

<script>
import FileUpload from "../components/FileUploads.vue";

export default {
  name: "",
  components: {
    FileUpload
  },
  props: {
    errors: Object,
    flash: Object,
    interview: Object
  },
  data() {
    return {
      candidates: {
        user_id: this.$page.auth.user.id,
        company_id: this.$page.auth.user.company.id
      },
      file: {
        url: "/upload-files",
        max: "2",
        fileSize: "1",
        type: "text/csv"
      },
      form: {
        interview: this.interview.id,
        company: this.interview.company_id,
        file: {}
      }
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    browse() {
      this.$refs.file.click();
    },
    change(e) {
      let uploadedFiles = this.$refs.files.files;
      for (var i = 0; i < uploadedFiles.length; i++) {
        this.form.files.push(uploadedFiles[i]);
      }
    }
  },
  computed: {},
  watch: {}
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
