<template>
  <div class="flex flex-col">
    <p class="font-medium text-xl mb-4">Settings</p>
    <!-- <div class="flex justify-between">
      <p class="float-left">Accepting Invites</p>
      <toggle-button
        :value="false"
        :color="{checked: '#6edaef', unchecked: '#fff'}"
        :sync="true"
        switch-color="#000"
        :width="35"
      />
    </div>
    <div class="flex justify-between">
      <p class="float-left">Public</p>
      <toggle-button
        :value="false"
        :color="{checked: '#6edaef', unchecked: '#fff'}"
        :sync="true"
        switch-color="#000"
        :width="35"
      />
    </div>-->
    <inertia-link
      class="bg-cherryred-primary font-bold text-white my-4 py-4 text-center rounded-lg"
      :href="this.$route('edit.interview', this.interview.id)"
    >Edit Interview</inertia-link>
    <!-- <inertia-link
      class="bg-cherryred-lightest font-bold text-cherryred-primary my-4 py-4 text-center"
    >Download Candidates</inertia-link>-->
    <button
      type="button"
      @click="deleteInterview(interview.id)"
      class="bg-yellow font-bold text-black my-4 py-4 text-center rounded-lg"
      value="Delete Interview"
    >
      <i class="fa fa-times mr-2 text-yellow bg-black p-1"></i>Delete Interview
    </button>
    <!-- <inertia-link
      class="bg-yellow font-bold text-black my-4 py-4 text-center"
      :href="this.$route('delete.interview', this.interview.id)"
    >
      <i class="fa fa-times mr-2 text-yellow bg-black p-1"></i>Delete Interview
    </inertia-link>-->
  </div>
</template>

<script>
import { ToggleButton } from "vue-js-toggle-button";
export default {
  name: "QuickSettings",
  components: {
    ToggleButton
  },
  props: {
    interview: Object
  },
  data() {
    return {};
  },
  methods: {
    deleteInterview(id) {
      let conf = confirm("Are you sure you wish to delete this interview?");
      if (conf) {
        this.$inertia.delete(this.$route("delete.interview", id));
      }
    }
  },
  computed: {},
  watch: {}
};
</script>
