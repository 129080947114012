<template>
  <transition type="modal-fade">
    <div class="modal w-1/2 mx-auto bg-white shadow-lg p-8">
      <div class="modal-header">
        <button type="button" class="text-black float-right" @click="close">
          <i class="fa fa-2x fa-times"></i>
        </button>
        <div class="flex flex-col w-2/4 justify-center items-center content-center ml-auto mr-auto">
          <p class="text-black text-center mx-auto ii--subheading-text my-8">{{header }}</p>
          <p class="text-black text-md my-8 text-center mx-auto break-word">{{ message }}</p>
        </div>
      </div>
      <div class="modal-body flex flex-col justify-center w-2/4 ml-auto mr-auto">
        <div v-if="this.fitType == 'Good'">
          <button
            type="button"
            class="bg-green w-full my-8 py-6 font-bold"
            @click="confirmCandidate()"
          >{{ button }}</button>
        </div>
        <div v-else>
          <button
            type="button"
            class="bg-iired w-full my-8 py-6 font-bold"
            @click="rejectCandidate()"
          >{{ button }}</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "",
  props: {
    fitType: String
  },
  data() {
    return {
      header: String,
      message: String,
      button: String,
      method: String
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    confirmCandidate() {
      console.log("confirm");
    },
    rejectCandidate() {
      console.log("reject");
    }
  },
  computed: {},
  watch: {
    fitType() {
      if (this.fitType == "Good") {
        this.header = "Header";
        this.message = "Good Fit";
        this.button = "Confirm";
      } else {
        this.header = "Are you sure this person is not a fit?";
        this.message =
          "If you are happy to reject this candidate, the template rejection letter will be sent out when you confirm below";
        this.button = "Reject Candidate";
      }
    }
  }
};
</script>
