<template>
  <layout>
    <h1>bleugh</h1>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout";

export default {
  components: {
    Layout
  }
};
</script>
