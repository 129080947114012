<template>
  <nav class="flex flex-col justify-center flex-wrap bg-white">
    <div class="flex justify-center text-black">
      <span class="bg-white h-24">
        <!-- <img src="/img/interview_intelligence_logo.png" /> -->
        <img class="h-20 mt-2" :src="image" />
      </span>
    </div>
  </nav>
</template>

<script>
export default {
  name: "GuestNavigation",
  props: {
    image: null
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
  watch: {}
};
</script>
