<template>
  <layout>
    <div class="flex flex-col justify-center content-center items-center h-auto">
      <p class="ii--header-text mt-10 mb-8">Sign In</p>
      <p class="mt-4 font-bold">Don't have an account?</p>
      <a href="#register" class="underline text-sm font-thin">Register Here</a>
      <!-- <inertia-link :href="$route('register')" class="underline text-sm font-thin">Register Here</inertia-link> -->
    </div>
    <div
      class="flex flex-wrap mx-1 overflow-hidden sm:mx-px md:mx-px lg:mx-px xl:mx-px mt-2 xl:mt-12 md:mt-8"
    >
      <div
        class="my-1 px-1 w-full md:w-full overflow-hidden sm:my-px sm:px-px md:my-px md:px-px lg:my-px lg:px-px xl:my-6 xl:px-px"
      >
        <login></login>
      </div>
      <div
        class="my-1 px-1 w-full overflow-hidden sm:my-px sm:px-px md:my-px md:px-px lg:my-px lg:px-px xl:my-6 xl:px-px"
        id="register"
      >
        <register></register>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout";
import Login from "../Pages/Auth/Login.vue";
import Register from "../Pages/Auth/Register.vue";

export default {
  components: {
    Layout,
    Login,
    Register
  },
  watch: {
    "$page.flash": {
      handler() {
        console.log($page.flash.success);
        alertify.success($page.flash.success);
      }
    }
  }
};
</script>
