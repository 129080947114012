<template>
  <layout class="h-screen">
    <div
      class="flex flex-col justify-center content-center items-center mx-auto bg-white w-2/3 mt-8"
    >
      <p class="ii--header-text text-black text-center mt-4">Reset Password</p>
      <form id="reset--form" class="mt-4 mb-4">
        <label for="password">Password:</label>
        <input
          placeholder="Password"
          class="ii--form-input-full text-black mb-4 focus:outline-none"
          name="password"
          type="password"
          autocapitalize="off"
          v-model="form.password"
          id="password"
          required
          aria-required="true"
        />

        <label for="confirm">Confirm Password:</label>
        <input
          placeholder="Confirm Password"
          class="ii--form-input-full text-black mb-4 focus:outline-none"
          name="confirm"
          autocapitalize="off"
          type="password"
          v-model="form.confirm"
          id="confirm"
          required
          aria-required="true"
        />
        <p class="text-red-500 text-xs italic text-right">Minimum of 8 characters.</p>
        <input
          type="button"
          @click="reset()"
          class="ii--button-darknavy text-white px-8 w-full mt-6"
          value="Reset Password"
        />
      </form>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout";

export default {
  name: "Password",
  props: {
    user: String,
    url: null
  },
  components: {
    Layout
  },
  data() {
    return {
      form: {
        password: "",
        confirm: ""
      }
    };
  },
  methods: {
    reset() {
      let urlSplit = this.$page.url.split("=");
      let e = urlSplit[1].split("&");
      let s = urlSplit[2];
      if (this.form.password == this.form.confirm) {
        if (this.form.password.length < 8 && this.form.confirm.length < 8) {
          alertify.error("Password is under 8 characters");
        } else {
          this.$inertia.post(
            this.$route("update.password", {
              id: this.user,
              expires: e[0],
              signature: s
            }),
            {
              password: this.form.password,
              password_confirmation: this.form.confirm
            }
          );
        }
      } else {
        alertify.error("Passwords do not match");
      }
    }
  }
};
</script>
