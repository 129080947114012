<template>
  <div class="flex justify-center">
    <form
      id="reset--form"
      class="bg-white mx-auto w-full md:w-full lg:w-full xl:w-1/2 py-16 px-20 mb-6 md:mx-2"
      @keydown.enter="reset()"
    >
      <input
        placeholder="Email"
        class="ii--form-input-full text-black mb-4 focus:outline-none"
        id="email"
        type="email"
        name="email"
        required
        autofocus
        autocapitalize="off"
        v-model="form.email"
      />
      <div class="flex justify-center mx-auto mt-4">
        <button
          type="button"
          @click="reset()"
          class="ii--button-darknavy px-8"
          value="Reset Password"
        >Reset Password</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "AuthPasswordReset",
  props: {},
  data() {
    return {
      form: {
        email: null
      }
    };
  },
  methods: {
    reset() {
      this.$inertia.post(this.$route("reset.password"), {
        email: this.form.email
      });
    }
  }
};
</script>
