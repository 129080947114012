<template>
  <div class="flex justify-center w-full">
    <form
      id="register--user"
      class="bg-darknavy-primary mx-auto px-20 mb-6 md:mx-2 w-full md:w-full lg:w-full xl:w-1/2"
      @submit.prevent="submit"
    >
      <div class="mb-8 -mx-20">
        <div
          class="flex flex-col justify-center content-center items-center bg-navy-primary py-10 top-0 relative w-full"
        >
          <p class="text-lg mb-2 font-bold">Start your FREE Trial today</p>
          <p>Register Below</p>
        </div>
      </div>

      <input
        placeholder="Business Name"
        class="ii--form-input w-full mb-4 mt-4 placeholder-white focus:outline-none"
        id="name"
        type="text"
        name="name"
        required
        autofocus
        v-model="registration.company_name"
      />
      <!-- <label class="block text-gray-700 text-sm font-bold mb-2" for="email">Email</label> -->
      <input
        placeholder="Email"
        class="ii--form-input w-full text-white placeholder-white focus:outline-none"
        id="registration--email"
        type="email"
        name="email"
        v-model="registration.email"
        required
      />
      <div class="flex items-center mt-4 mb-12">
        <input
          type="submit"
          class="bg-cherryred-primary text-white font-bold py-4 w-full mt-10 uppercase"
          value="Register"
          @click="register()"
        />
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "Register",
  data() {
    return {
      registration: {
        email: "",
        company_name: ""
      }
    };
  },
  methods: {
    register() {
      this.$inertia.post(this.$route("register"), {
        email: this.registration.email,
        company_name: this.registration.company_name
      });
    }
  },
  watch: {
    "$page.errors": {
      handler() {
        if (Object.keys(this.$page.errors).length > 0) {
          let error = Object.keys(this.$page.errors);
          let value = Object.values(this.$page.errors);
          let message = "Error: " + value[0];
          alertify.error(message);
        }
      }
    }
  }
};
</script>
