<template>
  <div class="flex flex-col bg-black text-white">
    <div v-for="(q, index) in questions" :key="index">
      <div v-if="index === currentQuestion">
        <div v-if="q.type == 'text'" class="px-2 mb-4">
          <p class="text-center text-2xl text-white">{{ q.question }}</p>
          <vue-editor
            id="quillAnswer"
            name="answer"
            class="text-black bg-white mt-12 h-3/4 noQuillBorder"
            :editorToolbar="editorToolbar"
            v-model="answers[index].answer"
            placeholder="Please enter your answer"
          ></vue-editor>
        </div>
        <div v-else>
          <interview-question-webcam
            @video-uploaded="videoCaptured"
            :question="q"
            :interview="interview"
            :index="index"
            :record="startRecording"
            :prepStart="prepStart"
          ></interview-question-webcam>
        </div>
      </div>
    </div>

    <!-- next button  -->
    <div class="flex justify-center mx-auto w-full lg:mt-0 px-2 relative bottom-0">
      <div v-if="currentQuestion+1 != totalQuestions" class="flex justify-center w-full">
        <button
          type="button"
          class="ii--button-red w-full lg:w-2/5 rounded-lg"
          @click="nextQuestion()"
        >Next Question</button>
      </div>
      <div v-else class="flex justify-center mx-auto w-full px-2 mt-12 lg:mt-0">
        <button
          type="button"
          class="ii--button-red w-full lg:w-2/5 rounded-lg"
          @click="submitAnswers()"
        >Submit</button>
      </div>
    </div>

    <!-- resources box -->
    <div
      class="hidden md:flex md:flex-col md:justify-center md:content-center md:items-center md:bg-white md:text-black md:w-full md:h-48 md:mb-8 md:mt-8 md:mx-auto"
      v-if="interview.files"
    >
      <div class="flex mx-auto justify-center items-center content-center">
        <p class="text-xl mt-2">Some interview resources</p>
      </div>
      <div class="flex mx-auto justify-between mt-3 w-full overflow-x-auto overflow-y-hidden">
        <div
          class="flex flex-col justify-center items-center content-center px-2"
          v-for="(file, index) in this.interviewFiles"
          :key="index"
          @click="showFile(file.file_location, file.file_name)"
        >
          <i class="far fa-4x fa-file-pdf mx-8" />
          <span class="text-md" :title="file.file_name">{{ file.file_name.substr(0,10) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import WebcamTimers from "./WebcamTimers";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

export default {
  name: "",
  components: {
    InterviewQuestionWebcam: () => import("./InterviewQuestionWebcam"),
    VueEditor,
    WebcamTimers
  },
  remember: ["answers"],
  props: {
    questions: Array,
    interview: Array | Object,
    started: false,
    files: Object | null,
    prep: Boolean
  },
  data() {
    return {
      currentQuestion: null,
      totalQuestions: null,
      answers: [],
      editorToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }]
      ],
      prepTimer: 120,
      startRecording: false,
      timer: null,
      recordingStopped: false,
      interviewFiles: null,
      attempts: null,
      prepStart: this.prep
    };
  },
  mounted() {
    this.checkAttempts();
    this.currentQuestion = 0;
    this.answers.push({
      questionNumber: "",
      answer: "",
      attempts: "",
      question: this.questions[this.currentQuestion].question,
      format: "text"
    });
    this.totalQuestions = this.questions.length;
    if (this.files != null) {
      this.interviewFiles = this.files.files;
    }
  },
  methods: {
    nextQuestion() {
      this.answers.push({
        questionNumber: "",
        answer: "",
        attempts: "",
        question: this.questions[this.currentQuestion].question,
        format: "text"
      });
      if (this.answers[this.currentQuestion].format == "text") {
        //handle text question
        let data = new FormData();
        data.append("question", this.currentQuestion + 1);
        data.append("attempts", this.attempts);
        data.append("company", this.interview.company_id);
        data.append("interview", this.interview.interview_id);
        data.append("candidate", this.interview.id);
        data.append("textAnswer", this.answers[this.currentQuestion].answer);

        axios.post("/text-question", data);
      }
      this.prepStart = true;
      this.currentQuestion++;
      alertify.notify(
        "Answer " + this.currentQuestion + " submitted",
        "success"
      );
    },
    previousQuestion() {
      this.currentQuestion--;
    },
    submitAnswers() {
      Swal.fire({
        title: "Submit Answers",
        text: "Are you sure you want to submit your answers",
        icon: "success",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: "roundAlert"
      }).then(result => {
        if (result.value) {
          this.$emit("submitted-answers", this.answers);
        } else if (result.dismiss == Swal.DismissReason.cancel) {
          Swal.close();
        }
      });
    },
    createFile(video, contentType = "", sliceSize = 512) {
      const byteCharacters = atob(video);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    videoCaptured(video) {
      this.answers[this.currentQuestion].questionNumber =
        this.currentQuestion + 1;
      this.answers[this.currentQuestion].answer = video.url;
      this.answers[this.currentQuestion].attempts = video.attempts;
      this.answers[this.currentQuestion].question = video.question;
      this.answers[this.currentQuestion].format = "video";
    },
    showFile(fileLocation, fileName) {
      let data = new FormData();
      data.append("location", fileLocation);
      axios
        .post(this.$route("candidate.file.download"), data, {
          responseType: "arraybuffer"
        })
        .then(response => {
          const blob = new Blob([response.data], {
            type: "response.headers.content-type"
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
        });
    },
    checkAttempts() {
      let data = new FormData();
      data.append("question", this.currentQuestion + 1);
      data.append("company", this.interview.company_id);
      data.append("interview", this.interview.interview_id);
      data.append("candidate", this.interview.id);
      axios.post(this.$route("candidate.attempted"), data).then(response => {
        if (response.data.success) {
          this.attempts = response.data.attempts;
        }
      });
    }
  },
  watch: {
    prep: {
      handler() {
        this.prepStart = this.prep;
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
