<template>
  <div>
    <div class="flex flex-col justify-center mx-auto mb-2 md:py-4 px-3">
      <p class="text-center text-2xl mb-4 text-white">{{ question.question }}</p>
      <!-- show how long they have to answer question -->
      <p class="text-center font-xs">you have 3 minutes to answer question</p>
      <div class="mt-3" :class="this.showRecording ? 'border-4 border-cherryred-primary' : ''">
        <video
          id="myVideo"
          ref="videoRecord"
          class="video-js vjs-fluid mx-auto vjs-big-play-centered"
        ></video>
      </div>
      <div class="flex flex-col lg:flex-row justify-center mt-6 lg:-mx-2" v-if="attempts > 0">
        <div class="w-full lg:w-1/2 lg:mx-2" v-if="attempts > 2">
          <button
            type="button"
            class="ii--button-red w-full rounded-lg"
            @click="startRecording()"
          >Start</button>
        </div>
        <div v-else class="w-full lg:w-1/2 lg:mx-2">
          <button
            type="button"
            class="ii--button-red w-full rounded-lg"
            @click="startRecording()"
          >Re-take</button>
        </div>
        <div class="w-full lg:w-1/2 lg:mx-2 mt-2 lg:mt-0">
          <button
            type="button"
            class="ii--button-red w-full lg:mx-2 rounded-lg"
            @click="stopRecording()"
          >Stop</button>
        </div>
      </div>
      <div v-else class="flex justify-center mt-2">
        <button
          type="button"
          class="ii--button-red mx-auto w-full px-6 mx-2 rounded-lg"
          @click="stopRecording()"
        >Finish Recording</button>
      </div>
      <p
        class="text-white text-center my-4 text-lg"
        v-if="attempts > 1"
      >You have {{ attempts }} attempts left</p>
      <p
        v-else-if="attempts <= 0"
        class="text-white text-center my-4 text-lg"
      >You have no attempts left</p>
      <p v-else class="text-white text-center my-4 text-lg">You have {{ attempts}} attempt left</p>
    </div>

    <div class="flex flex-col lg:flex-row mb-4">
      <!-- PREP -->
      <div class="flex flex-row lg:flex-col justify-center w-full lg:w-1/2 mx-auto">
        <p class="text-center text-base lg:text-xl">Preparation Time Remaining:</p>
        <div v-if="prepTimer == 120">
          <p class="text-center lg:mt-1 lg:mb-3 text-base lg:text-4xl ml-1 lg:ml-0">2:00</p>
        </div>
        <div v-else>
          <p
            class="text-center lg:mt-1 lg:mb-3 text-base lg:text-4xl ml-1 lg:ml-0"
            :class="prepTimer < '00:30' ? 'text-cherryred-primary text-base lg:text-4xl' : 'text-base lg:text-4xl'"
          >{{ prepTimer }}</p>
        </div>
      </div>
      <div class="flex flex-row lg:flex-col justify-center w-full lg:w-1/2 mx-auto">
        <p class="text-center text-base lg:text-xl">Question Time Remaining:</p>
        <div v-if="questionTimer == 180">
          <p class="text-center lg:mt-1 lg:mb-3 text-base lg:text-4xl ml-1 lg:ml-0">3:00</p>
        </div>
        <div v-else>
          <p
            class="text-center lg:mt-1 lg:mb-3 ml-1 lg:ml-0"
            :class="questionTimer < '00:30' ? 'text-cherryred-primary text-base lg:text-4xl' : 'text-base lg:text-4xl'"
          >{{ questionTimer }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//css styles
import "video.js/dist/video-js.css";
import "videojs-record/dist/css/videojs.record.css";

//core
import videojs from "video.js";
import Record from "videojs-record/dist/videojs.record.js";
import "webrtc-adapter";
import RecordRTC from "recordrtc";

//plugins
// import "videojs-record/dist/plugins/videojs.record.webm-wasm.js";
// import "videojs-record/dist/plugins/videojs.record.ts-ebml.js";

//required for Firefox
import "web-streams-polyfill/dist/polyfill.js";

export default {
  name: "InterviewQuestionWebcam",
  components: {},
  props: {
    question: Object,
    index: Number,
    interview: Object,
    record: false,
    prepStart: Boolean
  },
  data() {
    return {
      attempts: "",
      player: null,
      questionTimer: 180,
      qTimer: null,
      pTimer: this.prepStart,
      prepTimer: 120,
      showRecording: false,
      options: {
        controls: true,
        controlBar: {
          fullscreenToggle: false,
          volumePanel: false,
          cameraButton: false,
          recordToggle: false,
          playToggle: true
        },
        pip: false,
        width: 780,
        height: 600,
        plugins: {
          record: {
            audio: true,
            fluid: true,
            maxLength: 180,
            video: {
              framerate: {
                ideal: 30,
                max: 60
              },
              width: 640,
              height: 480
            },
            debug: false, //testing purposes true
            hotKeys: true,
            // timeSlice: 20000,
            frameWidth: 780,
            frameHeight: 600,
            videoMimeType: "video/mp4"
          }
        }
      }
    };
  },
  mounted() {
    window.playerEvents = this;
    this.playerInitialize();
    this.playerSetupEvents();
    this.previouslyAttempted();
    this.prepTimer = 120;
  },
  beforeDestroy() {
    this.playerDispose();
  },
  methods: {
    playerInitialize() {
      this.player = videojs(this.$refs.videoRecord, this.options);
      this.player.record().getDevice();
    },
    playerDispose() {
      this.player.dispose();
    },
    startRecording() {
      this.player.record().start();
      this.questionAttempt();
      this.recordingTimer();
      this.$emit("recording");
      this.showRecording = true;
      this.stopPrepTimer();
    },
    stopRecording() {
      this.player.record().stop();
      clearInterval(this.qTimer);
      this.questionTimer = 180;
      this.showRecording = false;
      this.pTimer = false;
    },
    playerEventError() {
      console.log(this.playerGetError());
    },
    playerGetError() {
      return this.player.error().message;
    },
    previouslyAttempted() {
      let data = new FormData();
      data.append("question", this.index + 1);
      data.append("company", this.interview.company_id);
      data.append("interview", this.interview.interview_id);
      data.append("candidate", this.interview.id);
      axios.post(this.$route("candidate.attempted"), data).then(response => {
        if (response.data.success) {
          this.attempts = response.data.attempts;
        }
      });
    },
    questionAttempt() {
      this.attempts--;
      //send truncate command
      let data = new FormData();
      data.append("question", this.index + 1);
      data.append("company", this.interview.company_id);
      data.append("interview", this.interview.interview_id);
      data.append("candidate", this.interview.id);
      axios.post("/truncate", data);
    },
    playerRecorded() {
      let video = this.player.recordedData;

      let data = new FormData();
      data.append("video", video, video.name);
      data.append("question", this.index + 1);
      data.append("attempts", this.attempts);
      data.append("company", this.interview.company_id);
      data.append("interview", this.interview.interview_id);
      data.append("candidate", this.interview.id);
      axios.post("/video-upload", data).then(response => {
        let video = {};
        video.url = response.data;
        video.attempts = this.attempts;
        video.question = this.question.question;
        this.$emit("video-uploaded", video);
        this.questionTimer = 180;
      });
    },
    playerSetupEvents() {
      this.player.on("error", () => {
        // window.playerEvents.playerEventError();
      });
      this.player.on("finishRecord", () => {
        // window.playerEvents.playerRecorded();
        this.playerRecorded();
      });
      this.player.on("timestamp", () => {
        if (this.player.recordedData && this.player.recordedData.length > 0) {
          let binaryData = this.player.recordedData[
            this.player.recordedData.length - 1
          ];
          this.segmentNumber++;
          this.convertToBase64(binaryData);

          //   let formData = new FormData();
          //   formData.append("SegmentNumber", this.segmentNumber);
          //   formData.append("video", binaryData);
          //   formData.append("question", this.index + 1);
          //   formData.append("attempts", this.attempts);
          //   formData.append("company", this.interview.company_id);
          //   formData.append("interview", this.interview.interview_id);
          //   formData.append("candidate", this.interview.id);

          //   axios.post("/candidate-video", formData);
        }
      });
    },
    convertToBase64(blob, segmentNumber) {
      let reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        this.uploadSegment(base64data);
      };
    },
    uploadSegment(videoData) {
      let formData = new FormData();

      formData.append("SegmentNumber", this.segmentNumber);
      formData.append("video", videoData);
      formData.append("question", this.index + 1);
      formData.append("attempts", this.attempts);
      formData.append("company", this.interview.company_id);
      formData.append("interview", this.interview.interview_id);
      formData.append("candidate", this.interview.id);

      axios.post("/candidate-video", formData);
    },
    recordingTimer() {
      let duration = moment.duration(this.questionTimer * 1000, "milliseconds");
      let interval = 1000;
      this.qTimer = setInterval(() => {
        duration = moment.duration(duration - interval, "milliseconds");
        this.questionTimer =
          Math.floor(duration.asHours()) +
          moment.utc(duration.asMilliseconds()).format("m:ss");
        if (duration.minutes() == 0 && duration.seconds() == 0) {
          clearInterval(this.qTimer);
          this.stopRecording();
        }
      }, interval);
    },
    startPrepTimer() {
      let duration = moment.duration(this.prepTimer * 1000, "milliseconds");
      let interval = 1000;
      if (this.attempts >= 0) {
        this.timer = setInterval(() => {
          duration = moment.duration(duration - interval, "milliseconds");
          this.prepTimer =
            Math.floor(duration.asHours()) +
            moment.utc(duration.asMilliseconds()).format("m:ss");
          if (duration.minutes() == 0 && duration.seconds() == 0) {
            clearInterval(this.timer);
            this.startRecording();
          }
        }, interval);
      }
    },
    stopPrepTimer() {
      this.recordingStopped = true;
      if (this.recordingStopped) {
        this.pTimer = false;
        clearInterval(this.timer);
      }
    }
  },
  watch: {
    record: {
      handler() {
        if (this.record) {
          setTimeout(() => {
            this.startRecording();
            //   this.recordingTimer();
          }, 1000);
        }
      },
      deep: true
    },
    prepStart: {
      handler() {
        this.pTimer = this.prepStart;
        if (this.pTimer == true) {
          this.startPrepTimer();
          this.prepTimer = 120;
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    questionDuration() {
      return moment.duration(this.timer.question).as("minutes");
    }
  }
};
</script>
