<template>
  <layout title="Getting Started" class="xl:h-screen">
    <div
      class="flex flex-wrap lg:flex-no-wrap justify-center content-center items-center mx-auto mb-20"
    >
      <div class="w-full xl:w-1/3 px-12 mx-2 mb-10 md:-mb-32 lg:mb-0">
        <div class="flex flex-col justify-center rounded overflow-hidden mt-20 xl:mt-10 h-2/4">
          <div
            class="flex justify-center items-center bg-white mx-auto rounded-full xl:mt-20 mb-6 p-12 z-40"
          >
            <img src="/img/icons/icon1.png" class="w-auto h-auto" />
          </div>
          <div class="w-full overflow-hidden h-2/4 -my-24 shadow-lg">
            <div class="bg-white w-full py-10 px-4">
              <div
                class="font-bold text-black text-md md:text-lg lg:text-xl mb-4 text-center xl:mt-10 mt-10 lg:h-16"
              >1. Configure your interview</div>
              <p
                class="text-gray-700 md:text-md xl:px-2 text-center break-words mb-10 text-sm"
              >Start the process by creating an interview question and deciding on your scoring criteria so you are in control</p>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full xl:w-1/3 px-12 mx-2 mb-10 md:-mb-32 lg:mb-0">
        <div class="flex flex-col justify-center rounded overflow-hidden mt-20 xl:mt-10 h-2/4">
          <div
            class="flex justify-center items-center bg-white mx-auto rounded-full xl:mt-20 mb-6 p-12 z-40"
          >
            <img src="/img/icons/icon2.png" class="w-auto h-auto" />
          </div>
          <div class="w-full overflow-hidden h-2/4 -my-24 shadow-lg">
            <div class="bg-white w-full py-10 px-4">
              <div
                class="font-bold text-black text-md md:text-lg lg:text-xl mb-4 text-center xl:mt-10 mt-10 lg:h-16"
              >2. Invite Candidates</div>
              <p
                class="text-gray-700 md:text-md xl:px-2 text-center break-words mb-10 text-sm"
              >Send your invites to job applicants to help screen potential candidates prior to a face to face interview, ensuring your time is spent efficiently</p>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full xl:w-1/3 px-12 mx-2 mb-10 md:-mb-32 lg:mb-0">
        <div class="flex flex-col justify-center rounded overflow-hidden mt-20 xl:mt-10 h-2/4">
          <div
            class="flex justify-center items-center bg-white mx-auto rounded-full xl:mt-20 mb-6 p-12 z-40"
          >
            <img src="/img/icons/icon3.png" class="w-auto h-auto" />
          </div>
          <div class="w-full overflow-hidden h-2/4 -my-24 shadow-lg">
            <div class="bg-white w-full py-10 px-4">
              <div
                class="font-bold text-black text-md md:text-lg lg:text-xl mb-4 text-center xl:mt-10 mt-10 lg:h-16"
              >3. Review & Hire</div>
              <p
                class="text-gray-700 md:text-md xl:px-2 text-center break-words mb-10 text-sm"
              >After a candidate completes a video interview, collaborate with your colleagues to score and rate the best person for the job</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center xl:bottom-0 xl:fixed w-full mx-auto z-50">
      <div class="bg-navy-primary uppercase flex justify-center w-full leading-loose py-10">
        <button
          class="ii--button-red text-center w-1/5 -mx-3 mb-8 rounded-lg"
          @click="gettingStarted()"
        >Let's get started</button>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout";
export default {
  name: "Started",
  components: {
    Layout
  },
  props: {
    errors: Object,
    user: Object,
    flash: Object
  },
  data() {
    return {};
  },
  methods: {
    gettingStarted() {
      this.$inertia.post(this.$route("getting.started"));
    }
  },
  computed: {},
  watch: {}
};
</script>
