<template>
  <layout>
    <div class="flex justify-center bg-background">
      <div class="h-auto w-full md:w-4/6 mx-auto text-black px-12 mt-6 min-h-screen">
        <p class="text-center font-bold text-2xl mt-3 mb-6">{{ changeHeader }}</p>
        <div>
          <div v-show="step === 1" class="h-auto mx-auto bg-white">
            <div class="flex justify-center">
              <div class="mt-8">
                <p
                  class="md:ii--header-text text-center text-2xl"
                >Hello {{ candidate.forename | ucfirst }} {{ candidate.surname | ucfirst }}</p>
                <p
                  class="my-4 text-center md:ii--subheading-text text-1xl font-thin"
                >{{interview.hiring}} has invited you to take an interview:</p>
                <p
                  class="my-4 text-center md:ii--subheading-text text-xl lg:text-2xl font-thin mx-2"
                >{{ form.interview.job_title }}</p>
                <div
                  class="display-inline md:text-center md:mt-4 px-4 md:p-0 justify-center mx-auto"
                >
                  <input
                    type="checkbox"
                    class="form-checkbox text-cherryred-primary mr-2"
                    v-model="terms"
                  />
                  <label class="display-inline items-center justify-center mx-auto">
                    I agree to the terms of service and
                    <span
                      class="underline"
                      @click="privacyPolicy = !privacyPolicy"
                    >privacy policy</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="flex justify-center mx-auto w-full mt-8">
              <button
                type="button"
                class="ii--button-red mb-10 mx-2 w-full lg:w-2/5 rounded-lg"
                @click="nextStep()"
                :class="terms ? '' :'ii--disabled'"
                :disabled="!terms"
              >Read Instructions</button>
            </div>
          </div>
          <!-- Job Description -->
          <div v-show="step === 2" class="h-auto mx-auto bg-white text-justify">
            <div class="flex flex-col justify-center mx-auto">
              <div class="mt-6 mx-auto md:w-3/4 w-full px-4 md:p-0">
                <p class="my-2 ii--subheading-text">Job Description</p>
                <p class="text-justify break-words" v-html="interview.description"></p>
              </div>

              <div class="mt-2" v-if="interview.files">
                <div class="flex mx-auto justify-center items-center content-center">
                  <p class="text-xl mt-2">Some interview resources</p>
                </div>
                <div
                  :class="interview.files.files.length > 1 ? 'justify-between lg:justify-around' : 'justify-center'"
                  class="flex mx-auto mt-3 w-3/4 lg:w-full overflow-x-auto overflow-y-hidden"
                >
                  <div
                    class="flex flex-col justify-center items-center content-center px-2"
                    v-for="(file, index) in interview.files.files"
                    :key="index"
                    @click="showFile(file.file_location, file.file_name)"
                  >
                    <i class="far lg:fa-4x fa-file-pdf mx-8" />
                    <span
                      class="text-sm lg:text-base"
                      :title="file.file_name"
                    >{{ file.file_name.substr(0,5) }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-center mx-auto w-full mt-6">
              <button
                type="button"
                class="ii--button-red mb-10 mx-2 w-full lg:w-2/5 rounded-lg"
                @click="nextStep()"
              >Continue</button>
            </div>
          </div>
          <!-- Interview information -->
          <div v-show="step === 3" class="h-auto mx-auto bg-white">
            <div class="flex justify-center mx-auto">
              <div class="mt-6 mx-auto md:w-3/4 w-full px-4 md:p-0">
                <p class="my-2 ii--subheading-text">Instructions:</p>
                <p
                  class="my-8"
                >Your interview has {{ interview.questions.question.length }} questions with 3 minutes to answer each question.</p>
                <!-- might change to a time limit per question -->
                <p
                  class="my-8"
                >You will be given a second chance to record any response, so don't worry if you are a little nervous and feel like you have made a mistake.</p>
                <p
                  class="my-8"
                >Questions are submitted as you complete them, so don't worry about saving your progress.</p>
                <p
                  class="my-8"
                >The first step is getting you setup, don't worry we will give you plenty of warning before your interview is going to start.</p>
                <p class="my-8 text-center text-xl font-bold">Good Luck!</p>
              </div>
            </div>
            <div class="flex justify-center mx-auto w-full mt-6">
              <button
                type="button"
                class="ii--button-red mb-10 mx-2 w-full lg:w-2/5 rounded-lg"
                @click="nextStep()"
              >Let's get setup</button>
            </div>
          </div>
          <!-- Prompt to take phone -->
          <div v-show="step === 4" class="h-auto mx-auto bg-white">
            <div class="flex justify-center mx-auto">
              <div class="mt-8 mx-auto w-full md:w-3/4">
                <p class="ii--header-text text-center">Getting ready</p>
                <p
                  class="text-base lg:ii--subheading-text text-center w-full mx-auto mt-4 px-2 lg:px-0"
                >First, let's take a profile picture that will be used along with your new interview</p>
              </div>
            </div>
            <div class="flex justify-center mx-auto w-full mt-4 md:mt-8">
              <button
                type="button"
                class="ii--button-red mx-auto w-full lg:w-2/5 mb-8 mt-2 rounded-lg"
                @click="nextStep()"
              >Take Photo</button>
            </div>
          </div>
          <!-- Take Photo -->
          <div v-show="step === 5" class="h-auto mx-auto bg-white">
            <div
              class="flex flex-col justify-center h-auto bg-white p-4 md:px-8 md:py-8 md:mt-8 w-full lg:w-4/6 mx-auto"
            >
              <p
                class="text-center text-base md:px-8 md:text-lg"
              >Look at the camera, press the button below to capture</p>
              <p
                class="hidden lg:block text-center text-sm md:px-8 md:text-lg md:mt-2 mt-1"
              >or upload an image from your gallery</p>
              <div v-if="this.candidate.image">
                <div class="flex flex-col justify-center content-center md:mt-6 mt-2">
                  <img :src="this.candidate.image" class="max-w-48 h-auto mx-auto" />
                </div>
              </div>
              <div v-else>
                <interview-capture-image
                  @image-captured="onCapture"
                  :company="this.company_id"
                  :interview="this.interview_id"
                  :candidate="this.candidate_id"
                />
              </div>
              <input
                ref="file"
                type="file"
                accept="image/x-png, image/gif, image/jpeg"
                class="hidden"
                @change="change"
              />
              <button
                type="button"
                class="hidden lg:block uppercase text-sm md:font-medium md:text-lg md:mt-6 mt-2 md:mb-6"
                @click="browse"
              >or click here to upload a photo</button>
              <div class="flex justify-center mx-auto w-full mt-2 md:mt-2 h-auto">
                <button
                  type="button"
                  class="ii--button-red py-8 mx-2 w-full md:w-1/2 rounded-lg"
                  :class="this.capturedImage ? 'w-1/2' : 'hidden'"
                  @click="retakePhoto()"
                >Re-take</button>
                <button
                  type="button"
                  class="ii--button-red py-8 mx-2 w-full md:w-1/2 rounded-lg"
                  @click="nextStep()"
                >Next</button>
              </div>
            </div>
          </div>
          <!-- interview overview -->
          <div v-show="step === 6" class="h-auto mx-auto bg-white">
            <div class="flex justify-center mx-auto px-2">
              <div class="mt-4 md:mt-8 mx-auto w-full md:w-3/4 text-center">
                <p class="my-8 text-md md:text-xl">
                  Total number of questions:
                  <span
                    class="font-bold mt-2"
                  >{{ interview.questions.question.length }}</span>
                </p>
                <p class="my-8 text-md md:text-xl">
                  Preparation time per question:
                  <br />
                  <span class="font-bold mt-2">2 Minutes</span>
                </p>
                <p class="my-8 text-md md:text-xl">
                  Maximum answer time per question:
                  <br />
                  <span class="font-bold">3 minutes</span>
                </p>
                <p class="my-8 text-md md:text-xl">
                  Number of retries per question:
                  <span class="font-bold">3</span>
                </p>
              </div>
            </div>
            <div class="flex flex-col justify-center mx-auto w-full mt-2 md:mt-8 px-4">
              <button
                type="button"
                class="ii--button-red mx-auto w-full lg:w-1/5 mb-10 rounded-lg"
                @click="interviewStarted()"
              >Start the interview</button>
              <p
                class="text-sm font-thin mx-auto -mt-8 mb-8"
              >Once you have clicked the above button, your interview and the time will start.</p>
            </div>
          </div>
          <!-- questions -->
          <div v-show="step === 7" class="h-auto w-full mx-auto bg-black">
            <div class="flex flex-col justify-center py-4 mt-4 mx-auto w-full lg:w-4/6">
              <interview-question
                @submitted-answers="questionAnswered"
                :questions="interview.questions.question"
                :interview="interview.candidates[0]"
                :files="interview.files"
                :started="started"
                :prep="this.prep"
              />
            </div>
          </div>
          <!-- confirm details and submit -->
          <div v-show="step === 8" class="bg-white">
            <div class="flex flex-col max-w-4xl mx-auto">
              <div class="mt-4 mb-4">
                <p class="text-center text-2xl">Contact Details</p>
                <p class="text-center text-lg">Do we have the correct details for you?</p>
              </div>
            </div>
            <div class="mb-4">
              <div class="flex justify-center content-center mt-8 mb-8">
                <img :src="candidate.image" class="rounded-full w-40 h-40" />
              </div>
              <div class="flex flex-col md:flex-row justify-center items-center">
                <div class="w-full px-3 mb-6 md:mb-0 md:w-1/2 mx-4">
                  <input
                    class="ii--form-input-full focus:outline-none"
                    id="first-name"
                    type="text"
                    placeholder="First Name"
                    autofocus
                    required
                    v-model="candidate.forename"
                  />
                </div>
                <div class="w-full px-3 mb-6 md:mb-0 md:w-1/2 mx-4">
                  <input
                    class="ii--form-input-full focus:outline-none"
                    id="last-name"
                    type="text"
                    placeholder="Last Name"
                    requried
                    v-model="candidate.surname"
                  />
                </div>
              </div>
              <div class="flex flex-col md:flex-row justify-center items-center">
                <div class="w-full px-3 mb-6 md:mb-0 md:w-1/2 mx-4">
                  <input
                    class="ii--form-input-full focus:outline-none"
                    id="email"
                    type="text"
                    placeholder="Email"
                    autofocus
                    required
                    v-model="candidate.email"
                  />
                </div>
                <div class="w-full px-3 mb-6 md:mb-0 md:w-1/2 mx-4">
                  <input
                    class="ii--form-input-full focus:outline-none"
                    id="phone-number"
                    type="tel"
                    pattern="[0-9]{11}"
                    placeholder="Phone Number"
                    v-model="candidate.phone_number"
                    required
                  />
                  <div v-if="candidate.phone_number == ''">
                    <p class="text-cherryred-primary text-xs font-hairline float-right">required</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-center mx-auto mt-8">
              <button
                type="button"
                class="ii--button-red w-full lg:w-1/2 mb-10 mx-2 rounded-lg"
                @click="confirmContactDetails()"
              >Confirm Contact Details & Submit Interview</button>
            </div>
          </div>
        </div>

        <div class="h-6 bottom-0 bg-buttonLower"></div>
        <!-- <button
          type="button"
          class="text-black text-lg mt-2 float-right mb-4"
          @click="helpModal = !helpModal"
        >Need Help?</button>-->
      </div>
      <div
        class="fixed inset-0 w-full h-screen flex items-center justify-center bg-modal"
        v-show="helpModal"
      >
        <tips
          :interview="interview"
          class="bg-white shadow-lg p-8"
          @close="helpModal = !helpModal"
        />
      </div>
      <div
        class="fixed inset-0 w-full h-screen flex items-center justify-center bg-modal"
        v-show="privacyPolicy"
      >
        <privacy class="bg-white px-8 py-8" @close="privacyPolicy = !privacyPolicy" />
      </div>
    </div>
    <footer class="bg-white w-full text-center p-4 pin-b">
      <p class="text-darknavy-primary">
        Powered by
        <a class="font-bold" href="#">Interview Intelligence</a>
      </p>
    </footer>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout";
import InterviewQuestion from "./InterviewQuestion.vue";
import InterviewCaptureImage from "./InterviewCaptureImage.vue";

export default {
  name: "ShowCandidateInterview",
  components: {
    Layout,
    InterviewQuestion,
    InterviewCaptureImage,
    Tips: () => import("./Tips.vue"),
    Privacy: () => import("./Privacy.vue")
  },
  remember: ["form"],
  props: {
    interview: Object
  },
  mounted() {
    if (this.candidate.image) {
      this.capturedImage = true;
    }
    if (this.interview.candidates[0].complete == 1) {
    }
    this.hasInterviewStarted();
  },
  data() {
    return {
      step: 1,
      helpModal: false,
      company_id: this.interview.company_id,
      interview_id: this.interview.id,
      candidate_id: this.interview.candidates[0].id,
      capturedImage: false,
      terms: false,
      privacyPolicy: false,
      candidate: {
        image: this.interview.candidates[0].photo,
        phone_number: this.interview.candidates[0].phone_number,
        email: this.interview.candidates[0].email,
        forename: this.interview.candidates[0].forename,
        surname: this.interview.candidates[0].surname
      },
      form: {
        interview: {
          job_title: this.interview.title,
          company: this.interview.company_id,
          interview: this.interview.id
        },
        candidate: this.interview.candidates[0].id,
        answers: {}
      },
      pageHeaderText: "",
      started: false,
      prep: false
    };
  },
  methods: {
    nextStep() {
      this.step++;
    },
    previousStep() {
      this.step--;
    },
    interviewStarted() {
      let data = new FormData();
      data.append("candidate", this.candidate_id);
      data.append("interview", this.interview.id);
      axios
        .post(this.$route("interview.started", this.candidate_id), data)
        .then(response => {
          this.step = 7;
          this.started = true;
        });
      this.prep = true;
    },
    hasInterviewStarted() {
      axios
        .get(this.$route("started.interview", this.candidate_id))
        .then(response => {
          if (response.data === 1) {
            this.step = 5;
          }
        });
    },
    retakePhoto() {
      this.candidate.image = null;
      this.capturedImage = false;
    },
    browse() {
      this.$refs.file.click();
    },
    change(e) {
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onloadend = () => {
        // const base64data = reader.result;
        this.uploadImage(reader.result);
        this.capturedImage = false;
      };
    },
    uploadImage(candidateImage) {
      let data = new FormData();
      data.append("image", candidateImage);
      data.append("candidate", this.candidate_id);
      axios.post("/image-captured", data);
      this.candidate.image = candidateImage;
    },
    onCapture(candidateImage) {
      this.capturedImage = true;
      this.candidate.image = candidateImage;
    },
    questionAnswered(answer) {
      this.form.answers = answer;
      let data = new FormData();
      data.append("answers", this.form);
      this.step++;
    },
    confirmContactDetails() {
      let form = new FormData();
      if (this.candidate.phone_number != "") {
        form.append("forename", this.candidate.forename);
        form.append("surname", this.candidate.surname);
        form.append("email", this.candidate.email);
        form.append("phone_number", this.candidate.phone_number);
        form.append("interview_id", this.interview.id);

        this.$inertia.post(
          this.$route("candidate.contact", this.interview.candidates[0].id),
          form
        );
      } else {
        alert("please enter a phone number");
      }
    },
    showFile(fileLocation, fileName) {
      let data = new FormData();
      data.append("location", fileLocation);
      axios
        .post(this.$route("candidate.file.download"), data, {
          responseType: "arraybuffer"
        })
        .then(response => {
          const blob = new Blob([response.data], {
            type: "response.headers.content-type"
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
        });
    }
  },
  watch: {
    terms: function() {
      if (this.terms) {
        let data = new FormData();
        data.append("interview_id", this.interview.id);
        data.append("candidate_id", this.interview.candidates[0].id);
        axios.post(this.$route("accepted.terms"), data);
      }
    }
  },
  computed: {
    changeHeader(val) {
      if (val.step == 1) {
        return "Interview: " + this.form.interview.job_title;
      } else if (val.step == 2) {
        return this.form.interview.job_title + " Job Description";
      } else if (val.step == 3) {
        return "Interview: " + this.form.interview.job_title;
      } else if (val.step == 4) {
        return "Getting Ready";
      } else if (val.step == 5) {
        return "Profile picture";
      } else if (val.step == 6) {
        return this.form.interview.job_title;
      } else if (val.step == 7) {
        return this.form.interview.job_title;
      } else if (val.step == 8) {
        return "Confirm Contact Details & Complete Interview";
      }
      return this.form.interview.job_title;
    }
  }
};
</script>
